import * as React from "react";
const SvgAppLoader = (props) => /* @__PURE__ */ React.createElement("svg", { className: "animated", id: "freepik_stories-software-engineer", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 500 500", xmlnsXlink: "http://www.w3.org/1999/xlink", "xmlns:svgjs": "http://svgjs.com/svgjs", ...props }, /* @__PURE__ */ React.createElement("style", null, "svg#freepik_stories-software-engineer:not(.animated) .animable {opacity: 0;}svg#freepik_stories-software-engineer.animated #freepik--App--inject-8 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}svg#freepik_stories-software-engineer.animated #freepik--Character--inject-8 {animation: 6s Infinite  linear wind;animation-delay: 0s;}            @keyframes floating {                0% {                    opacity: 1;                    transform: translateY(0px);                }                50% {                    transform: translateY(-10px);                }                100% {                    opacity: 1;                    transform: translateY(0px);                }            }                    @keyframes wind {                0% {                    transform: rotate( 0deg );                }                25% {                    transform: rotate( 1deg );                }                75% {                    transform: rotate( -1deg );                }            }        "), /* @__PURE__ */ React.createElement("g", { id: "freepik--Shadow--inject-8", className: "animable", style: {
  transformOrigin: "250px 416.24px"
} }, /* @__PURE__ */ React.createElement("ellipse", { id: "freepik--path--inject-8", cx: 250, cy: 416.24, rx: 193.89, ry: 11.32, style: {
  fill: "rgb(245, 245, 245)",
  transformOrigin: "250px 416.24px"
}, className: "animable" })), /* @__PURE__ */ React.createElement("g", { id: "freepik--App--inject-8", className: "animable", style: {
  transformOrigin: "191.283px 242.447px"
} }, /* @__PURE__ */ React.createElement("path", { d: "M137.12,182.41a1.14,1.14,0,0,1-1.14-1.14V89.71a1.14,1.14,0,1,1,2.28,0v91.56A1.14,1.14,0,0,1,137.12,182.41Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "137.12px 135.488px"
}, id: "el1c4atndsadm", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M167.64,182.41a1.14,1.14,0,0,1-1.14-1.14V89.71a1.15,1.15,0,1,1,2.29,0v91.56A1.15,1.15,0,0,1,167.64,182.41Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "167.645px 135.431px"
}, id: "ell26gleipr9p", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M167.64,121.37a1.15,1.15,0,0,1-.81-.33l-14.45-14.45L137.93,121a1.15,1.15,0,0,1-1.62-1.62L150.76,105,136.31,90.52a1.15,1.15,0,0,1,.81-2h30.52a1.15,1.15,0,0,1,1.06.71,1.14,1.14,0,0,1-.25,1.25L154,105l14.45,14.45a1.14,1.14,0,0,1-.81,2ZM139.88,90.85l12.5,12.5,12.5-12.5Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "152.389px 104.986px"
}, id: "elgc9xhbhl438", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M137.12,90.85a1.14,1.14,0,0,1-.81-1.95l15.26-15.26a1.14,1.14,0,0,1,1.62,0L168.45,88.9a1.14,1.14,0,0,1,0,1.62,1.16,1.16,0,0,1-1.62,0L152.38,76.07,137.93,90.52A1.15,1.15,0,0,1,137.12,90.85Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "152.38px 82.0761px"
}, id: "el78u7fumur7l", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M198.16,90.85a1.17,1.17,0,0,1-.81-.33L182.9,76.07,168.45,90.52a1.16,1.16,0,0,1-1.62,0,1.14,1.14,0,0,1,0-1.62l15.26-15.26a1.14,1.14,0,0,1,1.62,0L199,88.9a1.14,1.14,0,0,1-.81,1.95Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "182.915px 82.0761px"
}, id: "el9hojullrp4u", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M198.16,90.85a1.17,1.17,0,0,1-.81-.33,1.14,1.14,0,0,1,0-1.62l15.26-15.26a1.14,1.14,0,0,1,1.62,0L229.49,88.9a1.14,1.14,0,0,1,0,1.62,1.16,1.16,0,0,1-1.62,0L213.42,76.07,199,90.52A1.15,1.15,0,0,1,198.16,90.85Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "213.42px 82.0763px"
}, id: "elsptmikisyr", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M137.12,151.89a1.14,1.14,0,0,1-.81-1.95l30.52-30.52a1.15,1.15,0,0,1,1.62,1.62l-30.52,30.52A1.15,1.15,0,0,1,137.12,151.89Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "152.345px 135.521px"
}, id: "eli2ih32s33xg", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M167.64,151.89a1.15,1.15,0,0,1-.81-.33L136.31,121a1.15,1.15,0,0,1,1.62-1.62l30.52,30.52a1.14,1.14,0,0,1-.81,1.95Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "152.415px 135.501px"
}, id: "elu3tbd25llh9", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M167.64,182.41H137.12a1.14,1.14,0,0,1-1.06-.7,1.16,1.16,0,0,1,.25-1.25L150.76,166l-14.45-14.45a1.15,1.15,0,0,1,1.62-1.62l14.45,14.45,14.45-14.45a1.15,1.15,0,0,1,1.62,1.62L154,166l14.45,14.45a1.14,1.14,0,0,1-.81,1.95Zm-27.76-2.28h25l-12.5-12.5Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "152.382px 166.036px"
}, id: "el45l2b8hnpqm", className: "animable" }), /* @__PURE__ */ React.createElement("polygon", { points: "126.44 106.19 110.14 106.19 112.19 89.71 124.38 89.71 126.44 106.19", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "118.29px 97.95px"
}, id: "elwhupfwz1bo", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M126.44,107.33h-16.3a1.17,1.17,0,0,1-.86-.38,1.19,1.19,0,0,1-.28-.9l2.06-16.48a1.14,1.14,0,0,1,1.13-1h12.19a1.15,1.15,0,0,1,1.14,1l2,16.48a1.14,1.14,0,0,1-1.13,1.28Zm-15-2.29h13.7l-1.77-14.19H113.21Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "118.261px 97.95px"
}, id: "el72jhmkywwuj", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M228.68,90.85H107.56a1.15,1.15,0,1,1,0-2.29H228.68a1.15,1.15,0,1,1,0,2.29Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "168.12px 89.705px"
}, id: "el8u1b9lxr0vv", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M213.42,75.59h-61a1.15,1.15,0,0,1,0-2.29h61a1.15,1.15,0,1,1,0,2.29Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "183.027px 74.445px"
}, id: "elrw7on8wfmv", className: "animable" }), /* @__PURE__ */ React.createElement("rect", { x: 115.77, y: 140.95, width: 140.21, height: 270.65, rx: 11.27, style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "185.875px 276.275px"
}, id: "eligcpeen768e", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "elv35eeujs6ug" }, /* @__PURE__ */ React.createElement("rect", { x: 115.77, y: 140.95, width: 140.21, height: 270.65, rx: 11.27, style: {
  opacity: 0.2,
  transformOrigin: "185.875px 276.275px"
}, className: "animable" })), /* @__PURE__ */ React.createElement("rect", { x: 130.17, y: 140.95, width: 140.21, height: 270.65, rx: 11.27, style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "200.275px 276.275px"
}, id: "ellvkzl4nrat", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "eld3ze4s3pgzt" }, /* @__PURE__ */ React.createElement("rect", { x: 69.65, y: 210.86, width: 261.26, height: 130.82, rx: 6.57, style: {
  fill: "rgb(255, 255, 255)",
  transformOrigin: "200.28px 276.27px",
  transform: "rotate(90deg)"
}, className: "animable" })), /* @__PURE__ */ React.createElement("g", { id: "el4w4g81g0bqa" }, /* @__PURE__ */ React.createElement("rect", { x: 141.25, y: 187.25, width: 127.4, height: 112.46, rx: 8.14, style: {
  opacity: 0.1,
  transformOrigin: "204.95px 243.48px",
  transform: "rotate(87.22deg)"
}, className: "animable" })), /* @__PURE__ */ React.createElement("g", { id: "elul3z95xai5" }, /* @__PURE__ */ React.createElement("rect", { x: 193.55, y: 94.47, width: 13.46, height: 123.31, rx: 4.38, style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "200.28px 156.125px",
  transform: "rotate(90deg)"
}, className: "animable" })), /* @__PURE__ */ React.createElement("path", { d: "M151.66,159.28h0a3.16,3.16,0,0,1-3.16-3.15h0a3.17,3.17,0,0,1,3.16-3.16h0a3.17,3.17,0,0,1,3.16,3.16h0A3.16,3.16,0,0,1,151.66,159.28Z", style: {
  fill: "rgb(255, 255, 255)",
  transformOrigin: "151.66px 156.125px"
}, id: "el8zuy5cjdok9", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M161.05,159.28h0a3.16,3.16,0,0,1-3.16-3.15h0a3.17,3.17,0,0,1,3.16-3.16h0a3.16,3.16,0,0,1,3.15,3.16h0A3.16,3.16,0,0,1,161.05,159.28Z", style: {
  fill: "rgb(255, 255, 255)",
  transformOrigin: "161.045px 156.125px"
}, id: "el93l76cfy7ai", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M170.44,159.28h0a3.16,3.16,0,0,1-3.16-3.15h0a3.17,3.17,0,0,1,3.16-3.16h0a3.16,3.16,0,0,1,3.15,3.16h0A3.16,3.16,0,0,1,170.44,159.28Z", style: {
  fill: "rgb(255, 255, 255)",
  transformOrigin: "170.435px 156.125px"
}, id: "el4ib9xk7o50e", className: "animable" }), /* @__PURE__ */ React.createElement("rect", { x: 247.04, y: 152.21, width: 7.82, height: 7.82, rx: 1.72, style: {
  fill: "rgb(255, 255, 255)",
  transformOrigin: "250.95px 156.12px"
}, id: "elnv0ilp6mioc", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "elk2uof3gk3xe" }, /* @__PURE__ */ React.createElement("circle", { cx: 169.4, cy: 333.45, r: 21.91, style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "169.4px 333.45px",
  transform: "rotate(-22.5deg)"
}, className: "animable" })), /* @__PURE__ */ React.createElement("path", { d: "M247.61,323.49H204.89a2.75,2.75,0,0,1-2.74-2.74h0a2.75,2.75,0,0,1,2.74-2.74h42.72a2.74,2.74,0,0,1,2.74,2.74h0A2.74,2.74,0,0,1,247.61,323.49Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "226.25px 320.75px"
}, id: "elnh63ic2gt3l", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M247.61,336.19H204.89a2.75,2.75,0,0,1-2.74-2.74h0a2.75,2.75,0,0,1,2.74-2.74h42.72a2.74,2.74,0,0,1,2.74,2.74h0A2.74,2.74,0,0,1,247.61,336.19Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "226.25px 333.45px"
}, id: "elxs11vfpz0c", className: "animable" }), /* @__PURE__ */ React.createElement("rect", { x: 202.15, y: 343.41, width: 48.2, height: 5.48, rx: 2.74, style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "226.25px 346.15px"
}, id: "elcsg59d7gc1j", className: "animable" }), /* @__PURE__ */ React.createElement("rect", { x: 147.49, y: 367.66, width: 105.58, height: 31.85, rx: 9.82, style: {
  fill: "rgb(224, 224, 224)",
  transformOrigin: "200.28px 383.585px"
}, id: "elh2lq5creok", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "elewaye0p9jps" }, /* @__PURE__ */ React.createElement("rect", { x: 151.55, y: 180.97, width: 127.4, height: 112.46, rx: 8.14, style: {
  fill: "rgb(255, 255, 255)",
  transformOrigin: "215.25px 237.2px",
  transform: "rotate(85.54deg)"
}, className: "animable" })), /* @__PURE__ */ React.createElement("path", { d: "M171.61,305a8.64,8.64,0,0,1-8.6-8l-8.64-110.79a8.65,8.65,0,0,1,7.94-9.28l95.9-7.48a8.65,8.65,0,0,1,9.28,7.94l8.64,110.79a8.65,8.65,0,0,1-7.94,9.29l-95.9,7.47C172.06,305,171.84,305,171.61,305Zm-7.6-8a7.64,7.64,0,0,0,8.2,7l95.9-7.48a7.65,7.65,0,0,0,7-8.21l-8.64-110.79a7.64,7.64,0,0,0-8.21-7l-95.89,7.48a7.65,7.65,0,0,0-7,8.21Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "215.25px 237.213px"
}, id: "elcsyenfu48at", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "elj8ma5zgzt8d" }, /* @__PURE__ */ React.createElement("rect", { x: 199.69, y: 225.8, width: 37.49, height: 104.46, rx: 6.43, style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "218.435px 278.03px",
  transform: "rotate(85.54deg)"
}, className: "animable" })), /* @__PURE__ */ React.createElement("g", { id: "elc5bpjzm8uqh" }, /* @__PURE__ */ React.createElement("rect", { x: 149.08, y: 191.96, width: 78.16, height: 53.34, rx: 6.51, style: {
  fill: "rgb(38, 50, 56)",
  opacity: 0.2,
  transformOrigin: "188.16px 218.63px",
  transform: "rotate(85.54deg)"
}, className: "animable" })), /* @__PURE__ */ React.createElement("g", { id: "elc5f6p5o2cu" }, /* @__PURE__ */ React.createElement("rect", { x: 229.99, y: 163.62, width: 21.51, height: 45.01, rx: 5.23, style: {
  fill: "rgb(38, 50, 56)",
  opacity: 0.2,
  transformOrigin: "240.745px 186.125px",
  transform: "rotate(85.54deg)"
}, className: "animable" })), /* @__PURE__ */ React.createElement("g", { id: "el6b94f1iprvy" }, /* @__PURE__ */ React.createElement("rect", { x: 232.19, y: 191.86, width: 21.51, height: 45.01, rx: 5.23, style: {
  fill: "rgb(38, 50, 56)",
  opacity: 0.4,
  transformOrigin: "242.945px 214.365px",
  transform: "rotate(85.54deg)"
}, className: "animable" })), /* @__PURE__ */ React.createElement("g", { id: "elt4ac1wht3dr" }, /* @__PURE__ */ React.createElement("rect", { x: 234.39, y: 220.1, width: 21.51, height: 45.01, rx: 5.23, style: {
  fill: "rgb(38, 50, 56)",
  opacity: 0.6,
  transformOrigin: "245.145px 242.605px",
  transform: "rotate(85.54deg)"
}, className: "animable" })), /* @__PURE__ */ React.createElement("path", { d: "M226.25,156.64a.51.51,0,0,1-.51-.51V89.71a.51.51,0,1,1,1,0v66.42A.51.51,0,0,1,226.25,156.64Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "226.24px 122.87px"
}, id: "eltuxsb7v5frr", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M232.6,167H219.9a2.35,2.35,0,0,1-2.25-1.68l-2.73-9.28a2.35,2.35,0,0,1,2.25-3h18.16a2.35,2.35,0,0,1,2.25,3l-2.73,9.28A2.34,2.34,0,0,1,232.6,167Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "226.25px 160.02px"
}, id: "elwp4sft9htha", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M226.25,181.38a1.14,1.14,0,0,1,0-2.28,4,4,0,0,0,0-8,1.14,1.14,0,0,1-1.14-1.14V167a1.15,1.15,0,0,1,2.29,0v1.91a6.28,6.28,0,0,1-1.15,12.45Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "228.81px 173.669px"
}, id: "el5d2cmola1a4", className: "animable" })), /* @__PURE__ */ React.createElement("g", { id: "freepik--Character--inject-8", className: "animable", style: {
  transformOrigin: "351.479px 280.902px"
} }, /* @__PURE__ */ React.createElement("path", { d: "M402.72,196.57c1.27,2.93,2.31,5.71,3.33,8.63S408,211,408.79,214a86.06,86.06,0,0,1,2.08,9.31,48.8,48.8,0,0,1,.62,5.07,29.76,29.76,0,0,1-.15,6l-.06.41a3.75,3.75,0,0,1-.13.58,65.38,65.38,0,0,1-3.23,8c-1.17,2.51-2.5,4.88-3.82,7.23s-2.76,4.6-4.25,6.83-3,4.39-4.7,6.54a3.88,3.88,0,0,1-6.72-3.62l0-.09c.8-2.41,1.69-4.87,2.54-7.29s1.73-4.82,2.54-7.22,1.62-4.79,2.34-7.12,1.35-4.67,1.79-6.8l-.19,1a18.23,18.23,0,0,0-.29-2.87c-.2-1.15-.45-2.41-.77-3.68s-.67-2.57-1.06-3.88-.79-2.63-1.23-4c-.88-2.65-1.79-5.33-2.76-8l-2.93-7.93-.05-.14a7.72,7.72,0,0,1,14.33-5.75Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "399.691px 228.582px"
}, id: "el9gvszmzhbum", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "el0fo2zf0csbvn" }, /* @__PURE__ */ React.createElement("path", { d: "M402.72,196.57c1.27,2.93,2.31,5.71,3.33,8.63S408,211,408.79,214a86.06,86.06,0,0,1,2.08,9.31,48.8,48.8,0,0,1,.62,5.07,29.76,29.76,0,0,1-.15,6l-.06.41a3.75,3.75,0,0,1-.13.58,65.38,65.38,0,0,1-3.23,8c-1.17,2.51-2.5,4.88-3.82,7.23s-2.76,4.6-4.25,6.83-3,4.39-4.7,6.54a3.88,3.88,0,0,1-6.72-3.62l0-.09c.8-2.41,1.69-4.87,2.54-7.29s1.73-4.82,2.54-7.22,1.62-4.79,2.34-7.12,1.35-4.67,1.79-6.8l-.19,1a18.23,18.23,0,0,0-.29-2.87c-.2-1.15-.45-2.41-.77-3.68s-.67-2.57-1.06-3.88-.79-2.63-1.23-4c-.88-2.65-1.79-5.33-2.76-8l-2.93-7.93-.05-.14a7.72,7.72,0,0,1,14.33-5.75Z", style: {
  fill: "rgb(255, 255, 255)",
  opacity: 0.7,
  transformOrigin: "399.691px 228.582px"
}, className: "animable" })), /* @__PURE__ */ React.createElement("path", { d: "M388.89,259l-5.11,2.68,7.15,7.43a17.62,17.62,0,0,0,3.63-3.47,4.48,4.48,0,0,0-.9-6.24h0A4.48,4.48,0,0,0,388.89,259Z", style: {
  fill: "rgb(214, 152, 79)",
  transformOrigin: "389.61px 263.802px"
}, id: "elhl1pshyoli", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M381,270.19l2.95,2.18a3,3,0,0,0,3.85-.25l3.09-3-7.15-7.43L380.46,266A3,3,0,0,0,381,270.19Z", style: {
  fill: "rgb(214, 152, 79)",
  transformOrigin: "385.366px 267.321px"
}, id: "elknn76vy742l", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M307.44,211.63l-5.27-6.8-6.05,8.35s5.52,5.57,8.95,3.12Z", style: {
  fill: "rgb(214, 152, 79)",
  transformOrigin: "301.78px 210.876px"
}, id: "elq4guz5of7ys", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M293.32,203.64l-1.63,3.28a3,3,0,0,0,.93,3.75l3.5,2.51,6.05-8.35-4.8-2.51A3,3,0,0,0,293.32,203.64Z", style: {
  fill: "rgb(214, 152, 79)",
  transformOrigin: "296.776px 207.584px"
}, id: "eldac6aip2xo", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M394.86,417.14h-6.34l-.78-.73-1.17.73c-3.87,0-9.09-.14-11.58-1a1.63,1.63,0,0,1,.51-1.62s10.25-3.17,11.17-5.3h7.77", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "384.901px 413.18px"
}, id: "el18q6rlaamne", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M387,327.49c-1.27-32.78.8-58.69-2.31-71.26l-29-4.69s5.34,55.53,11.76,78.5,18,79.7,18,79.7h9.87S395.56,354.39,387,327.49Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "375.505px 330.64px"
}, id: "eldg4hgrkbcdj", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "elzxao6gha0z" }, /* @__PURE__ */ React.createElement("path", { d: "M366,279l-5.39,14c1.72,12.25,3.78,24.78,6,33.78C369,310.44,367.27,283.64,366,279Z", style: {
  opacity: 0.2,
  transformOrigin: "364.207px 302.89px"
}, className: "animable" })), /* @__PURE__ */ React.createElement("path", { d: "M312.72,407.74s14.59,1.11,16.19-.65l7.65,2.35-2.14,7.7-4.72-1.44-.53-.93-1.38.34c-3.82-1.17-13.92-4.42-16.1-6A1.66,1.66,0,0,1,312.72,407.74Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "324.125px 412.115px"
}, id: "elrktjb94kbcs", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M356.33,329.48a285.28,285.28,0,0,0,19.2-74.72l-31.66-5.18s-4.7,57.27-6.75,80.81C335,354.86,326.77,407,326.77,407L337,410.1S355.27,359.92,356.33,329.48Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "351.15px 329.84px"
}, id: "ele2zbccgk2l", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M387.35,166.05c-1.86,6-3,14.69-1.2,19.6-3.5,2.42-9,5.24-15.67,4.17s-4.77-5.4-2.65-7.46c5.13-1.71,5.67-4.12,4.82-6.43Z", style: {
  fill: "rgb(214, 152, 79)",
  transformOrigin: "376.601px 178.052px"
}, id: "eluds2me727yt", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M392.15,188.05a47.59,47.59,0,0,0-5.08-5.23c-1.56-1.08-14.51-2.69-17.6-2.58s-7.38,4.06-7.38,4.06Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "377.12px 184.142px"
}, id: "elhzd32wflgir", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "elr5ah0ifhkxa" }, /* @__PURE__ */ React.createElement("path", { d: "M392.15,188.05a47.59,47.59,0,0,0-5.08-5.23c-1.56-1.08-14.51-2.69-17.6-2.58s-7.38,4.06-7.38,4.06Z", style: {
  fill: "rgb(255, 255, 255)",
  opacity: 0.7,
  transformOrigin: "377.12px 184.142px"
}, className: "animable" })), /* @__PURE__ */ React.createElement("path", { d: "M370,158.88c-.2.59-.67,1-1.05.84s-.55-.72-.35-1.32.67-1,1.05-.83S370.2,158.29,370,158.88Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "369.3px 158.643px"
}, id: "elytisyskipyq", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M369.77,159.72a22.2,22.2,0,0,1-4.49,4.11,3.62,3.62,0,0,0,2.65,1.49Z", style: {
  fill: "rgb(99, 15, 15)",
  transformOrigin: "367.525px 162.52px"
}, id: "el3rbamjwaki8", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M370,154.79a.33.33,0,0,0,.28.2,2.91,2.91,0,0,1,2.22,1.43.35.35,0,0,0,.48.17.37.37,0,0,0,.17-.49,3.61,3.61,0,0,0-2.78-1.84.38.38,0,0,0-.37.53Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "371.577px 155.444px"
}, id: "elxpgjjt50u", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M390.53,163.93c-2.85,7.12-4.15,11.44-9,13.93-7.37,3.75-15.24-2.63-14.58-10.48.59-7.06,5.14-17.52,13.2-18A10.57,10.57,0,0,1,390.53,163.93Z", style: {
  fill: "rgb(214, 152, 79)",
  transformOrigin: "379.113px 164.164px"
}, id: "elxbnuefeulu", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M374.52,164.16a11.61,11.61,0,0,0,11.32,13c7.16,0,7.27-10.2,6-12.55,3.8-.66,6-5.88,3.68-9.18,3.49-2.74-4.58-6.9-14.4-7.44-10.83-.6-8.85,5.78-5.24,7.71C372.41,156.73,372.05,162.91,374.52,164.16Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "384.698px 162.555px"
}, id: "el16p27vonfvs", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M372.44,150.72s.69,8.39,7.74,13.44,13.32,5.52,13.32,5.52l1.74-5.52s-15.92-9.59-21.3-14.95A17.89,17.89,0,0,0,372.44,150.72Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "383.84px 159.445px"
}, id: "el0he0769scn5p", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "el8h6iqgovyf4" }, /* @__PURE__ */ React.createElement("path", { d: "M372.44,150.72s.69,8.39,7.74,13.44,13.32,5.52,13.32,5.52l1.74-5.52s-15.92-9.59-21.3-14.95A17.89,17.89,0,0,0,372.44,150.72Z", style: {
  fill: "rgb(255, 255, 255)",
  opacity: 0.6,
  transformOrigin: "383.84px 159.445px"
}, className: "animable" })), /* @__PURE__ */ React.createElement("path", { d: "M378.26,164.26a6.42,6.42,0,0,1-3.92,2.66c-2,.39-2.64-1.65-1.75-3.62.8-1.77,2.82-4.11,4.69-3.61S379.46,162.54,378.26,164.26Z", style: {
  fill: "rgb(214, 152, 79)",
  transformOrigin: "375.585px 163.295px"
}, id: "elp2kj81ca9z", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M391.84,187.15c-10.14-2.79-24.81-4.81-34.79-4.54a11.15,11.15,0,0,0-10.53,8.68c-3.38,14.66-3.9,41.08-4.88,67.61,21.76,11,46.13,6.64,46.13,6.64,1.29-5.19,1-22.86.55-32.34,2.7-9.32,7.59-19.73,11.3-32.09A11.18,11.18,0,0,0,391.84,187.15Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "370.86px 224.489px"
}, id: "elbd4leo6o8el", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "el2qa1uh305bt" }, /* @__PURE__ */ React.createElement("path", { d: "M391.84,187.15c-10.14-2.79-24.81-4.81-34.79-4.54a11.15,11.15,0,0,0-10.53,8.68c-3.38,14.66-3.9,41.08-4.88,67.61,21.76,11,46.13,6.64,46.13,6.64,1.29-5.19,1-22.86.55-32.34,2.7-9.32,7.59-19.73,11.3-32.09A11.18,11.18,0,0,0,391.84,187.15Z", style: {
  fill: "rgb(255, 255, 255)",
  opacity: 0.7,
  transformOrigin: "370.86px 224.489px"
}, className: "animable" })), /* @__PURE__ */ React.createElement("path", { d: "M359.86,181.14c-8.36-.48-13.56,3.65-15.69,16.27s-4.43,41.23-3.54,51.55c28.07,11.81,45.48,7,49.23,4.25,1.47-2.25.93-9.24.19-12a42.7,42.7,0,0,0,2.77-15.52c4.16-4,6-11.67,6.14-14.65,3.66-4.88,4.15-15.93,2.12-19.08S393.15,183,359.86,181.14Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "371.35px 218.825px"
}, id: "elw60wukc1ag", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "elcwxz5m2n538" }, /* @__PURE__ */ React.createElement("path", { d: "M344.66,251.07h0a.5.5,0,0,1-.5-.5c0-20.28,2.71-50.19,6.52-60.68a.5.5,0,0,1,.64-.3.49.49,0,0,1,.3.64c-3.41,9.39-6.44,37.59-6.46,60.34A.5.5,0,0,1,344.66,251.07Z", style: {
  fill: "rgb(255, 255, 255)",
  opacity: 0.2,
  transformOrigin: "347.906px 220.315px"
}, className: "animable" })), /* @__PURE__ */ React.createElement("path", { d: "M359.72,195c-1.52,2.77-3.06,5.24-4.72,7.79s-3.4,5-5.3,7.42a80.53,80.53,0,0,1-6.16,7.16,43.53,43.53,0,0,1-3.74,3.47,26.16,26.16,0,0,1-5,3.33l-.36.18a3.83,3.83,0,0,1-.56.22,24.74,24.74,0,0,1-9.26.9,30.33,30.33,0,0,1-4.14-.61,39.14,39.14,0,0,1-3.87-1,49.12,49.12,0,0,1-7.1-2.91c-1.13-.57-2.22-1.18-3.3-1.82s-2.08-1.3-3.21-2.12a3.86,3.86,0,0,1,3.14-6.89l.34.08,3.13.73c1.06.25,2.13.47,3.19.67a61.3,61.3,0,0,0,6.24.9,31.72,31.72,0,0,0,5.75,0,13.38,13.38,0,0,0,4.42-1.11l-.92.41a15.61,15.61,0,0,0,2-1.75c.77-.79,1.6-1.69,2.4-2.65s1.6-2,2.38-3.05,1.59-2.14,2.36-3.26c1.53-2.22,3.06-4.51,4.55-6.84s3-4.74,4.38-7l.07-.12A7.72,7.72,0,0,1,359.72,195Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "331.096px 204.459px"
}, id: "el4m0fe04lupi", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "el43n5w7817ra" }, /* @__PURE__ */ React.createElement("path", { d: "M359.72,195c-1.52,2.77-3.06,5.24-4.72,7.79s-3.4,5-5.3,7.42a80.53,80.53,0,0,1-6.16,7.16,43.53,43.53,0,0,1-3.74,3.47,26.16,26.16,0,0,1-5,3.33l-.36.18a3.83,3.83,0,0,1-.56.22,24.74,24.74,0,0,1-9.26.9,30.33,30.33,0,0,1-4.14-.61,39.14,39.14,0,0,1-3.87-1,49.12,49.12,0,0,1-7.1-2.91c-1.13-.57-2.22-1.18-3.3-1.82s-2.08-1.3-3.21-2.12a3.86,3.86,0,0,1,3.14-6.89l.34.08,3.13.73c1.06.25,2.13.47,3.19.67a61.3,61.3,0,0,0,6.24.9,31.72,31.72,0,0,0,5.75,0,13.38,13.38,0,0,0,4.42-1.11l-.92.41a15.61,15.61,0,0,0,2-1.75c.77-.79,1.6-1.69,2.4-2.65s1.6-2,2.38-3.05,1.59-2.14,2.36-3.26c1.53-2.22,3.06-4.51,4.55-6.84s3-4.74,4.38-7l.07-.12A7.72,7.72,0,0,1,359.72,195Z", style: {
  fill: "rgb(255, 255, 255)",
  opacity: 0.7,
  transformOrigin: "331.096px 204.459px"
}, className: "animable" })), /* @__PURE__ */ React.createElement("path", { d: "M395.38,167.67a1,1,0,0,1-1,.24,48.62,48.62,0,0,1-15.26-9.36c-7.57-6.94-8.22-14.89-4.86-11.09,1.86-2,9.76-4.44,17.08-1.27C399.08,149.56,402.81,160,395.38,167.67Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "385.866px 156.31px"
}, id: "elmjc9hdqh1is", className: "animable" }), /* @__PURE__ */ React.createElement("path", { d: "M388.91,145.35a14.15,14.15,0,0,1,3.8,14.37,16.35,16.35,0,0,0,4.64-1.78s1.17-5.61-1-9.22S389.38,144.2,388.91,145.35Z", style: {
  fill: "rgb(38, 50, 56)",
  transformOrigin: "393.287px 152.301px"
}, id: "elaq1jof5sqpk", className: "animable" }), /* @__PURE__ */ React.createElement("g", { id: "elmrcjq4q5u3" }, /* @__PURE__ */ React.createElement("path", { d: "M392.71,159.72a14.15,14.15,0,0,0-3.8-14.37.82.82,0,0,1,.62-.41C391.05,145.92,395.24,151.87,392.71,159.72Z", style: {
  opacity: 0.2,
  transformOrigin: "391.207px 152.33px"
}, className: "animable" }))), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("filter", { id: "active", height: "200%" }, /* @__PURE__ */ React.createElement("feMorphology", { in: "SourceAlpha", result: "DILATED", operator: "dilate", radius: 2 }), /* @__PURE__ */ React.createElement("feFlood", { floodColor: "#32DFEC", floodOpacity: 1, result: "PINK" }), /* @__PURE__ */ React.createElement("feComposite", { in: "PINK", in2: "DILATED", operator: "in", result: "OUTLINE" }), /* @__PURE__ */ React.createElement("feMerge", null, /* @__PURE__ */ React.createElement("feMergeNode", { in: "OUTLINE" }), /* @__PURE__ */ React.createElement("feMergeNode", { in: "SourceGraphic" }))), /* @__PURE__ */ React.createElement("filter", { id: "hover", height: "200%" }, /* @__PURE__ */ React.createElement("feMorphology", { in: "SourceAlpha", result: "DILATED", operator: "dilate", radius: 2 }), /* @__PURE__ */ React.createElement("feFlood", { floodColor: "#ff0000", floodOpacity: 0.5, result: "PINK" }), /* @__PURE__ */ React.createElement("feComposite", { in: "PINK", in2: "DILATED", operator: "in", result: "OUTLINE" }), /* @__PURE__ */ React.createElement("feMerge", null, /* @__PURE__ */ React.createElement("feMergeNode", { in: "OUTLINE" }), /* @__PURE__ */ React.createElement("feMergeNode", { in: "SourceGraphic" })), /* @__PURE__ */ React.createElement("feColorMatrix", { type: "matrix", values: "0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 " }))));
export default SvgAppLoader;
