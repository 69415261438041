import {
  createBrowserRouter,
  Route,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";
import {
  FluentProvider,
  Theme,
  // webDarkTheme,
  webLightTheme,
} from "@fluentui/react-components";
import { registerSW } from "virtual:pwa-register";
import { createRoot } from "react-dom/client";
import "./styles/index.scss";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import Milestones from "./components/milestones";
import Progress from "./components/progress/Index";
import Goals from "./components/goals";
import EngagementDetails from "./components/details";
import Dashboard from "./pages/Dashboard";
import EngagementWrapper from "./pages/EngagementWrapper";
import NextVisit from "./components/nextVisit";
import AnnualReview from "./components/annualReview";
import Submission from "./pages/Submission";
import ErrorMessage from "./components/errorMessage/errorMessage";

const lightTheme: Theme = {
  ...webLightTheme,
  fontFamilyBase: "ProximaVara",
  colorBrandBackground: "#005577",
  colorBrandBackgroundHover: "#005577",
  colorTransparentStrokeInteractive: "#005577",
  colorCompoundBrandStroke: "#005577",
  colorNeutralStroke1: "#005577",
  colorNeutralForeground1: "#231f20",
  colorNeutralForeground3: "#1F384A",
  colorCompoundBrandBackground: "#005577",
  colorCompoundBrandBackgroundHover: "#00709C",
  colorCompoundBrandBackgroundPressed: "#1F384A",
  //Hover Styles
  colorNeutralStroke1Hover: "#005577",
  colorNeutralForeground1Hover: "#005577",
  spacingHorizontalXXS: "0.125rem", //2px
  spacingHorizontalXS: "0.375rem", //6px
  spacingHorizontalS: "0.75rem", //12px
  spacingHorizontalM: "1.125rem", //18px
  spacingHorizontalL: "1.5rem", //24px
  spacingHorizontalXL: "1.875rem", //30px
  spacingHorizontalXXL: "2.625rem", //42px
  spacingHorizontalXXXL: "3rem", //48px
  spacingVerticalXXS: "0.125rem", //2px
  spacingVerticalXS: "0.375rem", //6px
  spacingVerticalS: "0.75rem", //12px
  spacingVerticalM: "1.125rem", //18px
  spacingVerticalL: "1.5rem", //24px
  spacingVerticalXL: "1.875rem", //30px
  spacingVerticalXXL: "2.625rem", //42px
  spacingVerticalXXXL: "3rem", //48px
  fontSizeBase400: "1rem", //16px
  fontSizeBase600: "1.125rem", //18px
  fontWeightRegular: 400,
  fontWeightBold: 700,
  borderRadiusSmall: "0.25rem", //4px
  borderRadiusMedium: "0.5rem", //8px
  borderRadiusLarge: "0.75rem", //12px
  borderRadiusXLarge: "1rem", //16px
};

const intervalMS = 15 * 60 * 1000;

registerSW({
  onRegisteredSW(swUrl, r) {
    r &&
      setInterval(async () => {
        if (r.installing || !navigator) return;

        if ("connection" in navigator && !navigator.onLine) return;

        const resp = await fetch(swUrl, {
          cache: "no-store",
          headers: {
            cache: "no-store",
            "cache-control": "no-cache",
          },
        });

        if (resp?.status === 200) await r.update();
      }, intervalMS);
  },
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Dashboard />} />
      <Route
        path="engagement/:id"
        element={<EngagementWrapper />}
        errorElement={<ErrorMessage />}
      >
        <Route
          path="details"
          element={<EngagementDetails />}
          errorElement={<ErrorMessage />}
        />
        <Route
          path="milestones"
          element={<Milestones />}
          errorElement={<ErrorMessage />}
        />
        <Route
          path="progress"
          element={<Progress />}
          errorElement={<ErrorMessage />}
        />
        <Route
          path="goals"
          element={<Goals />}
          errorElement={<ErrorMessage />}
        />
        <Route
          path="review"
          element={<AnnualReview />}
          errorElement={<ErrorMessage />}
        />
        <Route
          path="next-visit"
          element={<NextVisit />}
          errorElement={<ErrorMessage />}
        />
      </Route>
      <Route path="submission" element={<Submission />} />
      <Route
        path="*"
        element={
          <ErrorMessage error={{ status: 404, message: "Page not found" }} />
        }
      />
    </Route>
  )
);

// const prefersDarkMode =
//   window.matchMedia &&
//   window.matchMedia("(prefers-color-scheme: dark)").matches;

//const theme = prefersDarkMode ? darkTheme : lightTheme; // TODO: Enable once dark colors have been defined
const theme = lightTheme;

createRoot(document.getElementById("app")!).render(
  <FluentProvider theme={theme}>
    <RouterProvider router={router} />
  </FluentProvider>
);
