import styles from "./betaBanner.module.scss";

const BetaBanner: React.FC = () => {
  return (
    <div className={styles.betaBanner}>
      You are viewing the Beta version of Hono. Use for testing purposes only.
    </div>
  );
};

export default BetaBanner;
