import React, { forwardRef } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { Input } from "../input";
import dayjs from "dayjs";
import styles from "./datePicker.module.scss";
import { CalendarRegular } from "@fluentui/react-icons";

type CalendarIconProps = {
  onClick?: () => void;
  value?: Date | null;
  disabled?: boolean;
};

type Props = ReactDatePickerProps & {
  label: string;
  labelSuffix?: string;
  required?: boolean;
  id: string;
};

const minTime = dayjs(new Date(0)).set("hour", 6).set("minute", 0).toDate(); // 6.00AM
const maxTime = dayjs(new Date()).set("hour", 17).set("minute", 0).toDate(); // 6:00PM

const DatePicker: React.FC<Props> = (props) => {
  const CustomInput = forwardRef<HTMLImageElement, CalendarIconProps>(
    ({ onClick, value }, ref) => (
      <div onClick={onClick} ref={ref}>
        <Input
          label={props.label}
          id={props.id}
          iconSuffix={<CalendarRegular />}
          value={value ? dayjs(value).format("MMMM D, YYYY h:mm A") : ""}
          className={styles.input}
          required={props.required}
          readOnly
          disabled={props.disabled}
        />
      </div>
    )
  );

  return (
    <div className={styles.root}>
      <ReactDatePicker
        minTime={minTime}
        maxTime={maxTime}
        {...props}
        className={styles.datePicker}
        popperClassName={styles.popper}
        calendarClassName={styles.calendar}
        selected={props.selected}
        onChange={props.onChange}
        showTimeSelect
        timeIntervals={30}
        timeCaption="time"
        dateFormat="MMMM d, yyyy h:mm aa"
        customInput={<CustomInput value={props.selected} />}
        disabled={props.disabled}
        readOnly={props.readOnly}
      />
    </div>
  );
};

export default DatePicker;
