import {
  CompoundButton as CompoundButtonBase,
  mergeClasses,
} from "@fluentui/react-components";
import type { CompoundButtonProps } from "@fluentui/react-components";
import React from "react";
import styles from "./button.module.scss";

export const CompoundButton = (props: CompoundButtonProps) => {
  const className = mergeClasses(styles.button, props.className);
  return (
    <div className={styles.root}>
      <CompoundButtonBase
        shape="circular"
        {...props}
        className={className}
        icon={{
          className: styles.buttonIcon,
          children: props.icon as React.ReactElement,
        }}
      >
        {props.content}
      </CompoundButtonBase>
    </div>
  );
};
