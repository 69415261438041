import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { MilestoneChange } from "../../types";
import { AppContext, AppDispatchContext } from "../../AppContext";
import styles from "./progress.module.scss";
import { fieldInfo } from "../../constants";
import { Checkbox } from "../checkbox";
import { TextArea } from "../textArea";
import { ArrowSquareUpRightRegular } from "@fluentui/react-icons";
import { InfoLabel } from "@fluentui/react-components";
import { getGroupColor, removeGateway, toSentenceCase } from "../../utils";

const MilestoneStatement: React.FC<MilestoneChange> = (props) => {
  const {
    assignmentGroupName = "",
    assignmentId = "",
    skills = [],
    fields = [],
  } = props;
  const [assignmentNumber, assignmentName] =
    props.assignmentName.split(/\s-\s/);
  const { engagement, errors } = useContext(AppContext);
  const dispatch = useContext(AppDispatchContext);
  const existingMilestoneStatements =
    engagement?.milestoneStatements || ({} as Record<string, any>);
  const assignment = existingMilestoneStatements[assignmentId] || {};

  const [awardUsingCapability, setAwardUsingCapability] = useState(
    !!assignment["awardUsingCapability"]
  );

  useEffect(() => {
    const awardUsingCapabilityValue = !!assignment["awardUsingCapability"];
    setAwardUsingCapability(awardUsingCapabilityValue);
  }, [assignment]);

  const handleOnChange = useCallback(
    async (
      value: string | boolean | ChangeEvent<HTMLTextAreaElement>,
      assignmentId: string,
      assignment: Record<string, any>,
      field: string
    ) => {
      const updatedMilestoneStatements = {
        ...existingMilestoneStatements,
        [assignmentId]: {
          ...assignment,
          [field]: value,
        },
      };

      try {
        dispatch({
          type: "SET_ENGAGEMENT",
          payload: {
            milestoneStatements: updatedMilestoneStatements,
          },
        });
        await fetch(
          `/api/engagements?engagementId=${engagement?.engagementId}`,
          {
            method: "PUT",
            body: JSON.stringify({
              key: "milestone_statements",
              value: updatedMilestoneStatements,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );
      } catch (error) {
        console.error("Error updating milestone statements", error);
      }
    },
    [engagement, existingMilestoneStatements]
  );

  skills.sort((a, b) => {
    const aIndexMatches = a.skillName.match(/\d+/);
    const bIndexMatches = b.skillName.match(/\d+/);
    if (aIndexMatches && bIndexMatches) {
      return parseInt(aIndexMatches[0]) - parseInt(bIndexMatches[0]);
    }
    return 0;
  });

  const groupColor = getGroupColor(assignmentGroupName);

  return (
    <div className={styles.assignmentGroupContainer}>
      <div className={styles.skillHeader}>
        <h3>{toSentenceCase(assignmentGroupName)}</h3>
        <p className={styles.unitStandardName}>
          {toSentenceCase(removeGateway(assignmentName))}
          <span className={styles.unitStandardNumber}>
            ({assignmentNumber})
          </span>
        </p>
      </div>
      <div
        className={styles.skillDetails}
        style={{
          borderColor: groupColor,
          backgroundColor: `${groupColor}22`,
        }}
      >
        {skills.map((skill, index) => (
          <p key={index}>
            {skill.skillName}
            <span className={styles.skillLevel}> ({skill.skillLevel})</span>
            {index !== skills.length - 1 && (
              <hr
                style={{
                  borderColor: `${groupColor}44`,
                  border: "none",
                  borderTop: `1px solid ${groupColor}44`,
                  boxShadow: "none",
                }}
              />
            )}
          </p>
        ))}
      </div>
      {fields?.map((field, index) => {
        const id = `${assignmentId}_${field}`;

        const hasError = (errors || []).find((error) => error.fieldId === id);

        const fieldData = fieldInfo[field];
        const info = fieldData?.info;
        const value = assignment?.[field] || "";
        if (fieldData?.type === "checkbox") {
          return (
            <div className={styles.awardUsingCapabilityWrapper}>
              <Checkbox
                key={index}
                label="Award using capability"
                id={`${assignmentId}_${field}`}
                checked={awardUsingCapability}
                onChange={(_, data) => {
                  setAwardUsingCapability(!!data.checked);
                  handleOnChange(
                    !!data.checked,
                    assignmentId,
                    assignment,
                    field
                  );
                }}
                disabled={!engagement.isDraft}
              />
              <InfoLabel
                // @ts-ignore
                infoButton={info ? { size: "large", className: {} } : undefined}
                className={styles.fieldLabel}
                info={
                  <div className={styles.infoPopup}>
                    <strong>
                      In order to be recognised as capable, a learner must:
                    </strong>
                    <ul>
                      <li>
                        Understand all of the underpinning knowledge associated
                        with undertaking the skill.
                      </li>
                      <li>
                        Be competent in comparable and transferable skills
                        (commercially competent for L4 quals).
                      </li>
                      <li>
                        Have the confidence of the other members of the
                        assessment team that, should the opportunity present
                        itself in the future, the learner could undertake the
                        task safely, to the required standards, without
                        supervision.
                      </li>
                    </ul>
                    <p>
                      <a
                        href="https://bcito.sharepoint.com/sites/LearnDev2/SitePages/Assessing-Capability.aspx"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Assessing capability (HWR) <ArrowSquareUpRightRegular />
                      </a>
                    </p>
                  </div>
                }
              ></InfoLabel>
            </div>
          );
        }
        let isRequired = fieldData?.required;
        let isExpandDefault = fieldData?.expandDefault;

        if (
          awardUsingCapability &&
          (field === "siteAddress" ||
            field === "projectDescription" ||
            field === "learnerInvolvement")
        ) {
          isRequired = false;
        }

        if (field === "wrap-upStatement") {
          isExpandDefault = true;
        }
        return (
          <TextArea
            hasError={!!hasError}
            info={
              info ? (
                <div className={styles.infoPopup}>
                  {info.includeData && info.includeData.length > 0 && (
                    <>
                      <h4>What to include:</h4>
                      {info.includeData.map(
                        (
                          section: { title?: string; items?: string[] },
                          index: number
                        ) => (
                          <div key={index}>
                            {section.title && <p>{section.title}</p>}
                            {section.items && section.items.length > 0 ? (
                              <ul>
                                {section.items.map(
                                  (item: string, itemIdx: number) => (
                                    <li key={itemIdx}>{item}</li>
                                  )
                                )}
                              </ul>
                            ) : section.items ? (
                              <p>{section.items.join(", ")}</p>
                            ) : null}
                          </div>
                        )
                      )}
                    </>
                  )}

                  {info.example && (
                    <>
                      <h4>Example:</h4>
                      <p>{info.example}</p>
                    </>
                  )}
                  {info.link && (
                    <a
                      href={info.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {info.linkLabel}
                      <ArrowSquareUpRightRegular />
                    </a>
                  )}
                </div>
              ) : (
                ""
              )
            }
            key={index}
            id={id}
            helpText={fieldData?.help}
            label={fieldData?.label}
            required={isRequired}
            expandDefault={isExpandDefault}
            value={value}
            onChange={(value) =>
              handleOnChange(value, assignmentId, assignment, field)
            }
            disabled={!engagement.isDraft}
          />
        );
      })}
    </div>
  );
};

export default MilestoneStatement;
