import React from "react";
import { useRouteError } from "react-router-dom";
import { Button } from "../button";
import styles from "./errorMessage.module.scss";
import { ErrorCircleFilled } from "@fluentui/react-icons";

const homeURL = "/";

interface ErrorMessageProps {
  error?: { status?: number; message?: string } | null;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  const _error = error || (useRouteError() as any);

  const errorCode = _error?.status || "An unknown error has occurred";
  const errorMessage = _error?.message || "No error details available";

  function relaunchApp() {
    window.location.href = homeURL;
  }

  return (
    <div className={styles.errorMessage}>
      <div className={styles.errorWrapper}>
        <img
          className={styles.errorHeroIcon}
          src="/icons/wreckingball.svg"
          alt="Wrecking Ball Icon"
        />
        <div className={styles.errorSummary}>
          <h2>Oops, grab the duct tape!</h2>
          <p>Something went wrong while loading this page</p>
        </div>
        <div className={styles.errorHighlight}>
          <ErrorCircleFilled className={styles.errorIcon} />
          <div className={styles.errorTextWrapper}>
            <h3 className={styles.errorTitle}>{errorCode}</h3>
            <p className={styles.errorDescription}>{errorMessage}</p>
          </div>
        </div>
        <p>
          Please try restarting the app. If the issue persists, contact the
          myBCITO support team at{" "}
          <a href="mailto:mybcito@bcito.org.nz">mybcito@bcito.org.nz</a> and
          provide the error message above for assistance.
        </p>
        <Button
          appearance="primary"
          content="Restart app"
          onClick={relaunchApp}
        />
      </div>
    </div>
  );
};

export default ErrorMessage;
