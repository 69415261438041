import React, { useContext, useEffect, useMemo } from "react";
import Milestone from "./MileStone";
import { AssignmentGroups } from "../../types";
import styles from "./milestones.module.scss";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  mergeClasses,
  Spinner,
} from "@fluentui/react-components";
import NavigationButton from "../button/navigationButton";
import { getGroupColor, toSentenceCase } from "../../utils";
import { AppContext, AppDispatchContext } from "../../AppContext";

const Milestones: React.FC = () => {
  const [openItems, setOpenItems] = React.useState<number[]>([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [assignmentGroups, setAssignmentGroups] =
    React.useState<AssignmentGroups>({});

  const { engagement, milestones } = useContext(AppContext);
  const dispatch = useContext(AppDispatchContext);
  const handleToggle: AccordionToggleEventHandler<number> = (_, data) => {
    setOpenItems(data.openItems);
  };

  useEffect(() => {
    if (!engagement.learnerId || !engagement.courseId) {
      return;
    }

    const milestone = milestones?.find(
      (m) => m.engagementId === engagement.engagementId
    );

    const hasAssignmentGroups =
      Object.keys(milestone?.assignmentGroups || {}).length > 0;

    if (hasAssignmentGroups && milestone) {
      const { assignmentGroups } = milestone;
      setAssignmentGroups(assignmentGroups);
      return;
    }

    const fetchMilestones = async () => {
      try {
        setIsFetching(true);
        const response = await fetch(
          `/api/milestones?studentId=${engagement.learnerId}&courseId=${engagement.courseId}&engagementId=${engagement.engagementId}`
        );
        setIsFetching(false);
        if (!response.ok) {
          throw new Error(`Error fetching milestones: ${response.status}`);
        }
        const data = await response.json();

        dispatch({
          type: "SET_MILESTONE",
          payload: {
            engagementId: engagement?.engagementId,
            assignmentGroups: data,
          },
        });
        setAssignmentGroups(data);
      } catch (error) {
        console.error("Error fetching engagement details: ", error);
      }
    };

    fetchMilestones();
  }, [milestones, engagement.learnerId, engagement.courseId]);

  const totalCompletion = useMemo(() => {
    const total = Object.keys(assignmentGroups).reduce<number>(
      (total, groupName) => total + assignmentGroups[groupName].gradeWeighted,
      0
    );
    return Math.floor(total);
  }, [assignmentGroups]);

  const totalChangePercentage = engagement.milestones?.totalPercentageDiff || 0;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div>
          <h1>Milestones</h1>
          <p className={styles.subTitle}>
            A breakdown of all milestones left to achieve
          </p>
        </div>
        <div className={styles.completionPercentageWrapper}>
          {totalChangePercentage !== 0 && (
            <span className={styles.totalChangePercentage}>
              +{Math.ceil(totalChangePercentage)}%
            </span>
          )}
          <div className={styles.totalCompletionPercentage}>
            <span className={styles.completionPercentage}>
              {totalCompletion}%
            </span>
            <span className={styles.completionPercentageSubtext}>complete</span>
          </div>
        </div>
      </div>
      {!!isFetching && <Spinner label="Loading milestones..." size="large" />}
      <Accordion
        openItems={openItems}
        onToggle={handleToggle}
        multiple
        collapsible
      >
        {Object.keys(assignmentGroups).map((assignmentGroupName, index) => {
          const groupColor = getGroupColor(assignmentGroupName);
          const assignmentGroup = assignmentGroups[assignmentGroupName];
          const assignmentGroupId = assignmentGroup.assignmentGroupId;
          const percentageComplete = Math.floor(
            assignmentGroup.grade / (assignmentGroup.assignments.length || 1)
          );

          const {
            totalPercentageDiff,
            totalCompletionPercentage,
            ...milestoneItems
          } = engagement.milestones || {};

          const assignmentGroupChanges =
            milestoneItems?.[assignmentGroupId] || ({} as Record<string, any>);
          const changePercentage = assignmentGroupChanges.changePercentage || 0;

          if (assignmentGroupName.startsWith("Artefacts for experienced")) {
            return null;
          }

          return (
            <AccordionItem
              value={index}
              key={assignmentGroup.assignmentGroupId}
              className={styles.accordionItem}
            >
              <AccordionHeader
                icon={null}
                expandIcon={null}
                className={mergeClasses(
                  styles.accordionHeader,
                  percentageComplete !== 100
                    ? styles.notComplete
                    : styles.complete,
                  openItems.includes(index) && styles.open
                )}
                style={{ borderLeftColor: groupColor }}
              >
                <div className={styles.contentWrapper}>
                  <span>{toSentenceCase(assignmentGroupName)}</span>
                  <div className={styles.assignmentGroupPercentageWrapper}>
                    {changePercentage !== 0 && (
                      <span className={styles.changePercentage}>
                        +{Math.ceil(changePercentage)}%
                      </span>
                    )}
                    <span>{percentageComplete}%</span>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionPanel className={styles.accordionPanel}>
                <Milestone {...assignmentGroup} groupColor={groupColor} />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      <div className={styles.buttonContainer}>
        <NavigationButton
          destination={"progress"}
          content={"Progress review"}
        />
      </div>
    </div>
  );
};

export default Milestones;
