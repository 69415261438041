import React, { useContext } from "react";
import styles from "./lastEngagement.module.scss";
import { BlueAccordion } from "../blueAccordion";
import { AppContext } from "../../AppContext";
import { transformDbToUI } from "../../utils";

export const LastEngagement: React.FC = () => {
  const { engagement } = useContext(AppContext);
  const latestFaceToFaceEngagement = engagement?.latestFaceToFace;
  const latestFaceToFace = transformDbToUI(latestFaceToFaceEngagement || {});
  const engagementDate = new Date(
    Number(latestFaceToFace.scheduledTime)
  ).toLocaleDateString();

  return (
    latestFaceToFaceEngagement && (
      <BlueAccordion title="Last face to face">
        <>
          <div className={styles.engagementLocationWrapper}>
            <div>
              <p>
                Date:
                <span>{engagementDate} </span>
              </p>
              <p>
                Location: <span>{latestFaceToFace.location}</span>
              </p>
              <p>
                Participants: <span>{latestFaceToFace.participants}</span>
              </p>
            </div>
            <div>
              <p>
                Method:<span>{latestFaceToFace.engagementMethod}</span>
              </p>
              <p>
                Site safety:<span>{latestFaceToFace.siteSafety}</span>
              </p>
            </div>
          </div>

          <div className={styles.engagementDetailWrapper}>
            <h4>Current project:</h4>
            <p>{latestFaceToFace.currentWork}</p>
          </div>
          <div className={styles.engagementDetailWrapper}>
            <h4>Recent projects:</h4>
            <p>{latestFaceToFace.projectsWorkedOn}</p>
          </div>
          {!!latestFaceToFace.learnerEngagement && (
            <div className={styles.engagementDetailWrapper}>
              <h4>Learner engagement:</h4>
              <p>{latestFaceToFace.learnerEngagement}</p>
            </div>
          )}
          {!!latestFaceToFace.languageLiteracyNumeracy && (
            <div className={styles.engagementDetailWrapper}>
              <h4>Language, literacy and numeracy:</h4>
              <p>{latestFaceToFace.languageLiteracyNumeracy}</p>
            </div>
          )}
          {!!latestFaceToFace.evaluatorComments && (
            <div className={styles.engagementDetailWrapper}>
              <h4>Evaluator comments:</h4>
              <p>{latestFaceToFace.evaluatorComments}</p>
            </div>
          )}
          {!!latestFaceToFace.learnerComments && (
            <div className={styles.engagementDetailWrapper}>
              <h4>Learner comments:</h4>
              <p>{latestFaceToFace.learnerComments}</p>
            </div>
          )}
        </>
      </BlueAccordion>
    )
  );
};
