import {
  makeStyles,
  Button as ButtonBase,
  shorthands,
  tokens,
  ButtonProps,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    minWidth: "min-content",
  },
  root: {
    margin: "10px",
    ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalXXL),
    fontWeight: "bold",
    fontSize: tokens.fontSizeBase600,
    whiteSpace: "nowrap",
  },
});

export const Button = (props: ButtonProps) => {
  const styles = useStyles();
  return (
    <ButtonBase shape="circular" className={styles.root} {...props}>
      {props.content}
    </ButtonBase>
  );
};
