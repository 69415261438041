import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import styles from "./details.module.scss";
import StudentDetails from "./StudentDetails";
import { Input } from "../input";
import { TextArea } from "../textArea";
import { Checkbox } from "../checkbox";
import Banner from "../banner";
import { AppContext, AppDispatchContext } from "../../AppContext";
import { Dropdown, Label, Option } from "@fluentui/react-components";
import { CalendarRegular, ChevronDown32Regular } from "@fluentui/react-icons";
import { NavigationButton } from "../button/navigationButton";

type CalendarIconProps = {
  onClick?: () => void;
  value?: Date | null;
};

const EngagementDetails: React.FC = () => {
  const [bannerVisibility, setBannerVisibility] = useState(false);
  const { engagement } = useContext(AppContext);
  const appDispatch = useContext(AppDispatchContext);
  const [siteSafety, setSiteSafety] = React.useState<string>(
    engagement?.siteSafety || "Proceeded as normal"
  );

  useEffect(() => {
    setSiteSafety(engagement?.siteSafety || "Proceeded as normal");
  }, [engagement]);

  const initialEngagementDateTime = engagement.scheduledTime
    ? new Date(Number(engagement.scheduledTime))
    : new Date();

  const [engagementDateTime, setEngagementDateTime] = useState<Date | null>(
    initialEngagementDateTime
  );

  useEffect(() => {
    const lastAnnualReview = engagement.latestAnnualReview;
    const lastFaceToFace = engagement.latestFaceToFace;
    const engagementScheduledTime = engagement.scheduledTime;

    if (lastAnnualReview || (!lastAnnualReview && lastFaceToFace)) {
      const engagementDate = lastAnnualReview?.engagement_date
        ? lastAnnualReview?.engagement_date
        : lastFaceToFace?.engagement_date;

      if (engagementDate) {
        setBannerVisibility(false);
        const isLastAnnualReviewOlderThan11Months =
          dayjs(new Date(Number(engagementScheduledTime))).diff(
            new Date(Number(engagementDate)),
            "month"
          ) >= 11;

        const wasShownAlready = localStorage.getItem(
          `annualReviewReminderBanner-${engagement?.engagementId}`
        );
        if (isLastAnnualReviewOlderThan11Months && !wasShownAlready) {
          setBannerVisibility(true);
        }
      }
    }
  }, [engagement?.latestAnnualReview, engagement?.scheduledTime]);

  const CustomInput = forwardRef<HTMLImageElement, CalendarIconProps>(
    ({ onClick, value }, ref) => (
      <div onClick={onClick} ref={ref}>
        <Input
          label="Engagement date"
          id="engagementDate"
          iconSuffix={<CalendarRegular />}
          value={value ? dayjs(value).format("MMMM D, YYYY h:mm A") : ""}
          required={true}
          readOnly
          disabled={!engagement?.isDraft}
        />
      </div>
    )
  );

  const onChange = useCallback(
    async (data: { key: string; value: any }) => {
      await fetch(`/api/engagements?engagementId=${engagement?.engagementId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
    },
    [engagement]
  );

  const minTime = dayjs(engagementDateTime)
    .set("hour", 6)
    .set("minute", 0)
    .toDate(); // 6.00AM
  const maxTime = dayjs(engagementDateTime)
    .set("hour", 17)
    .set("minute", 0)
    .toDate(); // 5:00PM

  return (
    <form className={styles.root}>
      <h1>Details</h1>
      <p className={styles.subTitle}>
        When, where and who attended the face-to-face
      </p>

      <section className={styles.studentContainer}>
        <StudentDetails />
      </section>
      <section className={styles.engagementDateContainer}>
        <div>
          <DatePicker
            popperClassName={styles.popper}
            calendarClassName={styles.calendar}
            selected={engagementDateTime}
            onChange={(date: Date) => {
              setEngagementDateTime(date);
              const newEngagementDateTime = dayjs(date).valueOf().toString();
              appDispatch({
                type: "SET_ENGAGEMENT",
                payload: {
                  scheduledTime: newEngagementDateTime,
                },
              });
              onChange({
                key: "engagement_date",
                value: newEngagementDateTime,
              });
            }}
            showTimeSelect
            timeCaption="time"
            //minDate={new Date()}
            minTime={minTime}
            maxTime={maxTime}
            dateFormat="MMMM d, yyyy h:mm aa"
            customInput={<CustomInput value={engagementDateTime} />}
            disabled={!engagement?.isDraft}
          />
        </div>

        <div className={styles.siteSafety}>
          <Label size="large" htmlFor="siteSafety">
            <span className={styles.label}>Site safety 5x5</span>
            <span className={styles.labelSuffix}>(required)</span>
          </Label>
          <div
            className={
              !engagement?.isDraft
                ? styles.dropdownContainerDisabled
                : styles.dropdownContainer
            }
          >
            <Dropdown
              aria-labelledby="siteSafety"
              id="siteSafety"
              className={
                !engagement?.isDraft ? styles.dropdownDisabled : styles.dropdown
              }
              value={siteSafety}
              expandIcon={<ChevronDown32Regular />}
              onOptionSelect={(_, data) => {
                setSiteSafety(data.optionText ?? "");
                appDispatch({
                  type: "SET_ENGAGEMENT",
                  payload: { siteSafety: data.optionText },
                });
                onChange({ key: "site_safety_5x5", value: data.optionText });
              }}
              disabled={!engagement?.isDraft}
            >
              <Option value="proceed_as_normal">Proceeded as normal</Option>
              <Option value="location_change_site_condition">
                Location changed due to site conditions (i.e noise, dust)
              </Option>
              <Option value="location_change_weather">
                Location changed due to weather conditions
              </Option>
            </Dropdown>
          </div>
        </div>
      </section>
      <TextArea
        label="Participants"
        required
        helpText="Who was present, and their role in the assessment team"
        id="participants"
        value={engagement?.participants || ""}
      />
      <TextArea
        label="Location"
        required
        helpText="Include site address (street/lot number, street name, suburb) and site name (if applicable)"
        id="location"
        value={engagement?.location || ""}
      />
      <Checkbox
        label="This engagement is also an annual review"
        id="isAnnualReview"
        checked={!!engagement?.isAnnualReview}
        onChange={(_, data) => {
          appDispatch({
            type: "SET_ENGAGEMENT",
            payload: { isAnnualReview: !!data.checked },
          });
          appDispatch({ type: "SET_ANNUAL_REVIEW", payload: !!data.checked });
          onChange({ key: "is_annual_review", value: data.checked.toString() });
        }}
        disabled={!engagement?.isDraft}
      />

      {bannerVisibility && (
        <section>
          <Banner
            text="It has been more than 11 months since the previous annual review, these questions should be included in this engagement"
            onCloseHandler={() => {
              localStorage.setItem(
                `annualReviewReminderBanner-${engagement?.engagementId}`,
                "true"
              );
              setBannerVisibility(false);
            }}
          />
        </section>
      )}

      <TextArea
        label="Notes"
        required={false}
        expandDefault
        helpText="Prepare general discussion notes ahead of the face to face"
        id="notes"
        extraLabel="not submitted"
        value={engagement?.notes || ""}
      />
      <div className={styles.buttonContainer}>
        <NavigationButton destination={"milestones"} />
      </div>
    </form>
  );
};

export default EngagementDetails;
