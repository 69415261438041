import React from "react";
import { useContext, useEffect } from "react";
import { AuthDispatchContext } from "./AuthContext";
import { AppContext } from "./AppContext";
import { getCookie } from "./utils";
import { appInsights } from "./AppInsights";

const AuthLoader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const authDispatch = useContext(AuthDispatchContext);
  const { online } = useContext(AppContext);

  const initiateMicrosoftLogin = () => {
    window.location.href = `/api/microsoft-auth/oauth`;
  };

  useEffect(() => {
    (async () => {
      if (!online) return;
      try {
        const response = await fetch("/api/me/info");
        if (!response.ok) {
          await fetch(`/api/auth/oauth`, { method: "DELETE" });
          window.location.href = "/api/auth/oauth";
        }

        const user = await response.json();
        authDispatch({ type: "SET_USER_INFO", payload: user });
        appInsights.setAuthenticatedUserContext(user.id, "", true);

        // check if msAuth cookie is set
        const masAuthCookieValue = getCookie("msauth");

        if (masAuthCookieValue) {
          // MsAuth check if stored access token in DB is valid or renew it if its expired
          // PUT request to /api/microsoft-auth/oauth

          try {
            const response = await fetch("/api/microsoft-auth/oauth", {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (!response.ok) {
              throw new Error("Error renewing microsoft access toke");
            }
          } catch (error) {
            console.error(error);
          }

          authDispatch({ type: "SET_LOGIN_STATUS", payload: true });
          return;
        }
        initiateMicrosoftLogin();
        authDispatch({ type: "SET_LOGIN_STATUS", payload: true });
      } catch (error) {
        console.error("Error in fetching user info", error);
        authDispatch({ type: "SET_LOGIN_STATUS", payload: false });
        return;
      }
    })();
  }, [online]);

  return <>{children}</>;
};

export default AuthLoader;
