import React from "react";
import { DismissCircleRegular, InfoFilled } from "@fluentui/react-icons";
import styles from "./banner.module.scss";

interface BannerProps {
  text: string;
  onCloseHandler: () => void;
}

const Banner: React.FC<BannerProps> = ({ text, onCloseHandler }) => {
  return (
    <div className={styles.root}>
      <InfoFilled />
      <p>{text}</p>
      <DismissCircleRegular
        className={styles.interactive}
        tabIndex={0}
        aria-label="Close information banner"
        onClick={() => onCloseHandler()}
      />
    </div>
  );
};

export default Banner;
