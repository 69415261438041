import {
  Input as InputBase,
  InputOnChangeData,
  InputProps,
  Label,
  mergeClasses,
} from "@fluentui/react-components";
import styles from "./input.module.scss";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import debounce from "lodash/debounce";
import { TEngagement } from "../../types";
import { uiToDBColumnMap } from "../../utils";
import { AppContext } from "../../AppContext";

type Props = {
  id: string;
  label: string;
  labelSuffix?: string;
  direction?: "horizontal" | "vertical";
  value?: string;
  required?: boolean;
  iconSuffix?: React.ReactNode;
  iconPrefixSrc?: string;
} & InputProps;

export const Input: React.FC<Props> = ({
  label,
  labelSuffix = "required",
  id,
  direction = "vertical",
  required,
  iconSuffix,
  ...props
}) => {
  const [value, setValue] = useState<string>(props.value || "");
  const { engagement } = useContext(AppContext);

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);
  const debouncedChangeHandler = useCallback(
    debounce(async (value, id) => {
      const dbKey = uiToDBColumnMap[id as keyof TEngagement];
      await fetch(`/api/engagements?engagementId=${engagement?.engagementId}`, {
        method: "PUT",
        body: JSON.stringify({
          key: dbKey,
          value: value,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
    }, 1500),
    [engagement]
  );

  const onChange: InputProps["onChange"] = useCallback(
    (_: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      setValue(data.value);
      debouncedChangeHandler(data.value, id);
    },
    [id]
  );

  const className =
    direction === "vertical"
      ? styles.verticalContainer
      : styles.horizontalContainer;
  return (
    <div className={className}>
      <Label size="large" htmlFor={id}>
        <span className={styles.label}>{label}</span>
        {labelSuffix ? (
          <span className={required ? styles.labelSuffix : styles.optional}>
            {" "}
            ({required ? "required" : "optional"})
          </span>
        ) : (
          <></>
        )}
      </Label>
      <div
        className={
          props.disabled ? styles.inputContainerDisabled : styles.inputContainer
        }
      >
        <InputBase
          {...props}
          size="large"
          id={id}
          value={value}
          onChange={onChange}
          className={mergeClasses(styles.input, props.className)}
        />
        {iconSuffix && <div className={styles.icon}>{iconSuffix}</div>}
      </div>
    </div>
  );
};
