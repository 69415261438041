import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { AppContext, AppDispatchContext } from "../../AppContext";
import styles from "./subHeader.module.scss";
import { useMatch, useNavigate } from "react-router-dom";
import {
  CalendarLock28Regular,
  DismissCircleFilled,
  HomeRegular,
  WifiOffRegular,
} from "@fluentui/react-icons";
import { mergeClasses, Switch } from "@fluentui/react-components";
import { SubMenu } from "./SubMenu";

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

type CalendarIconProps = {
  onClick?: () => void;
};

type SwipeDirection = "left" | "right";

const subHeaderRef = React.createRef<{
  swipeDateChange: (direction: SwipeDirection) => void;
}>();

export const swipeDateChange = (direction: SwipeDirection) => {
  subHeaderRef.current?.swipeDateChange(direction);
};

const SubHeader: React.FC = () => {
  const { online, offlineMode } = useContext(AppContext);
  const navigate = useNavigate();
  const dispatch = useContext(AppDispatchContext);
  const match = useMatch("/engagement/:engagementId/:tab");
  const editMode = typeof match?.params?.tab !== "undefined";
  const [startDate, setStartDate] = useState(new Date());
  const [isPast, setIsPast] = useState(false);
  const [isFuture, setIsFuture] = useState(false);

  const CalenderIcon = forwardRef<HTMLImageElement, CalendarIconProps>(
    ({ onClick }, ref) => (
      <img
        src="/icons/calendar.svg"
        alt="calendar"
        onClick={onClick}
        ref={ref}
      />
    )
  );

  const updateDate = (date: Date) => {
    setStartDate(date);
    setIsPast(dayjs(date).isBefore(dayjs(), "day"));
    setIsFuture(dayjs(date).isAfter(dayjs(), "day"));

    dispatch({
      type: "SET_ENGAGEMENT_DATE",
      payload: dayjs(date).hour(6).minute(0).second(0).millisecond(0).valueOf(),
    });
  };

  useImperativeHandle(subHeaderRef, () => ({
    swipeDateChange: (direction: SwipeDirection) => {
      const newDate =
        direction === "left"
          ? dayjs(startDate).add(1, "day")
          : dayjs(startDate).subtract(1, "day");
      updateDate(newDate.toDate());
    },
    onDateChange: (date: Date) => {
      updateDate(date);
    },
  }));

  const handleClick = () => {
    dispatch({ type: "SET_EDIT_MODE", payload: false });
    navigate("/");
  };

  const toggleOfflineMode = () => {
    dispatch({ type: "SET_OFFLINE_MODE", payload: !offlineMode });
    dispatch({ type: "SET_NETWORK_STATUS", payload: offlineMode });
  };

  return (
    <>
      <div
        className={mergeClasses(
          styles.root,
          isPast || isFuture ? styles.notToday : ""
        )}
      >
        {editMode ? (
          <div
            className={styles.backToHome}
            tabIndex={0}
            role="button"
            aria-label="Back to dashboard"
            onClick={() => handleClick()}
            onKeyDown={() => handleClick()}
          >
            <HomeRegular />
            <span>Dashboard</span>
          </div>
        ) : (
          <div className={styles.engagementPeriodContainer}>
            <DatePicker
              disabled={!online}
              wrapperClassName={styles.datepickerWrapper}
              popperClassName={styles.popper}
              calendarClassName={styles.calendar}
              selected={startDate}
              onChange={updateDate}
              tabIndex={0}
              customInput={
                <div className={styles.dateContainer}>
                  {online ? <CalenderIcon /> : <CalendarLock28Regular />}
                  <span>{dayjs(startDate).format("dddd Do MMMM")}</span>
                </div>
              }
            />
            {isPast ? (
              <button
                className={styles.past}
                aria-label="Viewing past engagements, click to return to today"
                onClick={() => updateDate(new Date())}
              >
                Viewing past engagements
                <DismissCircleFilled />
              </button>
            ) : (
              <></>
            )}
            {isFuture ? (
              <button
                className={styles.past}
                aria-label="Viewing future engagements, click to return to today"
                onClick={() => updateDate(new Date())}
              >
                Viewing future engagements
                <DismissCircleFilled />
              </button>
            ) : (
              <></>
            )}
          </div>
        )}
        <div className={styles.rightBlock}>
          <SubMenu />
        </div>
      </div>
      {!online && offlineMode ? (
        <div className={styles.offlineMessage}>
          <div className={styles.offlineTextWrapper}>
            <WifiOffRegular />
            <strong>Offline</strong>(offline mode)
          </div>
          <Switch
            className={styles.switchOverride}
            name="offlineMode"
            value="offlineMode"
            checked={offlineMode}
            onClick={toggleOfflineMode}
          />
        </div>
      ) : !online ? (
        <div className={styles.offlineMessage}>
          <div className={styles.offlineTextWrapper}>
            <WifiOffRegular />
            <strong>Offline</strong>(no network connection)
          </div>
        </div>
      ) : (
        <> </>
      )}
    </>
  );
};

export default SubHeader;
