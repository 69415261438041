import { InfoLabel } from "@fluentui/react-components";
import React, { useContext, useEffect, useState } from "react";
import OutcomeRating from "./OutcomeRating";
import { Skill as TSkill } from "../../types";
import styles from "./milestone.module.scss";
import { AppContext } from "../../AppContext";
import { toSentenceCase } from "../../utils";
import { getAssessmentPrompt } from "../../assessment-prompt";

type Props = TSkill & {
  assignmentGroupId: number;
  assignmentId: number;
  assignmentName: string;
  assignmentNumber: string;
  assignmentGroupName: string;
  onSkillUpdate: (data: Record<string, any>) => void;
  onResetSkillChange: () => void;
};

const Skill: React.FC<Props> = (props) => {
  const {
    criterion,
    longDescription,
    skillLevels,
    id: skillId,
    points,
    assignmentGroupId,
    assignmentId,
    assignmentNumber,
    currentSkillLevel,
  } = props;

  const { engagement } = useContext(AppContext);
  const { totalPercentageDiff, totalCompletionPercentage, ...milestoneItems } =
    engagement?.milestones || {};
  const milestoneChanges = milestoneItems || {};

  const skillChange =
    milestoneChanges[assignmentGroupId]?.[assignmentId]?.[skillId];

  const changedSkillLevel = skillChange
    ? skillLevels?.find((r) => r.id === skillChange.changedSkillLevelId)
    : null;

  useEffect(() => {}, [engagement]);

  const [sanitizedDescription, setSanitizedDescription] =
    useState(longDescription);

  useEffect(() => {
    const sanitizeHTML = (html: string) => {
      const div = document.createElement("div");
      div.innerHTML = html;

      // Remove <img> tags
      const imgs = div.getElementsByTagName("img");
      while (imgs[0]) {
        imgs[0].parentNode?.removeChild(imgs[0]);
      }

      // Remove <br> tags
      const brs = div.getElementsByTagName("br");
      while (brs[0]) {
        brs[0].parentNode?.removeChild(brs[0]);
      }

      // remove style/class attributes
      const spans = div.getElementsByTagName("span");
      for (let i = 0; i <= spans.length; i++) {
        spans[i]?.removeAttribute("style");
        spans[i]?.removeAttribute("class");
      }

      // Replace &nbsp; with space
      let sanitizedHTML = div.innerHTML;
      sanitizedHTML = sanitizedHTML.replace(/&nbsp;/g, " ");

      // Replace • with <li> if not already inside a <ul>
      sanitizedHTML = sanitizedHTML.replace(/(?!<\/?ul[^>]*>)•/g, "<li>");

      // Ensure each <li> is closed with </li>
      sanitizedHTML = sanitizedHTML.replace(
        /<li>([^<]*?)(?=<li>|<\/?[^ul])/g,
        "<li>$1</li>"
      );

      // Wrap groups of <li> with <ul> if not already inside a <ul>
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = sanitizedHTML;

      const lis = tempDiv.querySelectorAll("li");
      lis.forEach((li) => {
        if (!li.parentElement || li.parentElement.tagName !== "UL") {
          const ul = document.createElement("ul");
          li.parentNode?.insertBefore(ul, li);
          ul.appendChild(li);
        }
      });

      sanitizedHTML = tempDiv.innerHTML;

      // Remove redundant <ul></ul> pairs
      sanitizedHTML = sanitizedHTML.replace(/<\/ul><ul>/g, "");

      // Remove empty <p></p> elements
      sanitizedHTML = sanitizedHTML.replace(/<p>\s*<\/p>/g, "");

      // Format <p> elements starting with a number in the format #.#
      sanitizedHTML = sanitizedHTML.replace(
        /<p>(\d+\.\d+)([^<]*)<\/p>/g,
        "<p><strong>$1</strong> —$2</p>"
      );

      // Wrap all caps text followed by a space and numbers in a span with className bookReference, ignoring commas
      sanitizedHTML = sanitizedHTML.replace(
        /\b([A-Z]+(?:, [A-Z]+)* \d+(?:,\s*\d+)*)\b/g,
        `<span class="${styles.bookReference}">$1</span>`
      );

      return sanitizedHTML;
    };

    setSanitizedDescription(
      sanitizeHTML(
        getAssessmentPrompt(
          engagement.courseId,
          assignmentNumber,
          criterion,
          longDescription || ""
        )
      )
    );
  }, [longDescription]);

  return (
    <div key={skillId} className={styles.outcome}>
      <InfoLabel
        info={
          <div
            className={styles.infoPopup}
            dangerouslySetInnerHTML={{
              __html: sanitizedDescription || "",
            }}
          />
        }
        className={styles.outcomeLabel}
      >
        {toSentenceCase(criterion)}
      </InfoLabel>

      <div className={styles.ratingWrapper}>
        <OutcomeRating
          skillId={skillId}
          assignmentId={assignmentId}
          assignmentGroupId={assignmentGroupId}
          ratings={skillLevels || []}
          currentSkillLevel={currentSkillLevel}
          changedSkillLevel={changedSkillLevel}
          isGoalSet={skillChange?.goal || false}
          pointsPossible={points}
          isCapstoneOutcome={!!criterion?.match(/Capstone/)}
          onSkillUpdate={props.onSkillUpdate}
          resetSkillChange={props.onResetSkillChange}
        />
      </div>
    </div>
  );
};

export default Skill;
