import React from "react";
import { AssignmentGroup } from "../../types";
import styles from "./milestone.module.scss";
import {
  Accordion,
  AccordionToggleEventHandler,
} from "@fluentui/react-components";
import Assignment from "./Assignment";

const Milestone: React.FC<AssignmentGroup & { groupColor: string }> = (
  props
) => {
  const [openItems, setOpenItems] = React.useState<number[]>([]);
  const { groupColor, assignments, assignmentGroupId, assignmentGroupName } =
    props;

  const handleToggle: AccordionToggleEventHandler<number> = (_, data) => {
    setOpenItems(data.openItems);
  };

  return (
    <Accordion
      openItems={openItems}
      onToggle={handleToggle}
      multiple
      collapsible
      className={styles.root}
      style={{ borderLeftColor: groupColor }}
    >
      {(assignments || []).map((assignment, index) => {
        const { id: assignmentId, name, grade, skills = [] } = assignment;
        const matches = name.match(/(\d+)?(.*)/);
        const assignmentNumber = matches?.[1] || "";
        const assignmentName = matches?.[2].replace(/^\s+?-/, "") || "";

        return (
          <Assignment
            index={index}
            key={assignmentId}
            assignmentName={assignmentName}
            assignmentNumber={assignmentNumber}
            assignmentId={assignmentId}
            grade={grade}
            skills={skills}
            assignmentGroupId={assignmentGroupId}
            assignmentGroupName={assignmentGroupName}
          />
        );
      })}
    </Accordion>
  );
};

export default Milestone;
