import React from "react";
import { Button } from "../components/button";
import styles from "./submission.module.scss";
import { useNavigate } from "react-router-dom";

const Submission: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <h1>Engagement complete</h1>
        <p>The engagement has been submitted successfully.</p>
        <div className={styles.buttonContainer}>
          <Button
            content="Back to dashboard"
            className={styles.button}
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </div>
  );
};

export default Submission;
