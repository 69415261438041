import { uniq } from "lodash";
import { courseLevelToSkills, fields } from "./constants";
import {
  AssignmentGroups,
  MilestoneChange,
  TEngagement,
  TEngagementDb,
} from "./types";

export const dbColumnToUiMap: Record<keyof TEngagementDb, keyof TEngagement> = {
  id: "engagementId",
  timestamp: "scheduledTime",
  teacher_id: "teacherId",
  student_id: "learnerId",
  course_id: "courseId",
  engagement_type: "engagementType",
  engagement_date: "scheduledTime",
  engagement_method: "engagementMethod",
  location: "location",
  site_safety_5x5: "siteSafety",
  participants: "participants",
  projects_worked_on: "projectsWorkedOn",
  current_work: "currentWork",
  agreed_milestone_movements: "agreedMilestoneMovements",
  learning_environment: "learningEnvironment",
  scope_of_work: "upcomingScopeOfWork",
  evaluator_comments: "evaluatorComments",
  learner_comments: "learnerComments",
  previous_goals_progress: "previousGoalsProgress",
  agreed_goals_for_next_period: "agreedGoalsForNextPeriod",
  annual_progress_review: "annualProgressReview",
  annual_development_goals: "annualDevelopmentGoals",
  annual_support_plan: "annualSupportPlan",
  check_in_type: "checkInType",
  check_in_progress: "checkInProgress",
  check_in_fees: "checkInFees",
  check_in_learner_engagement: "checkInLearnerEngagement",
  check_in_lln: "checkInLLN",
  check_in_learning_environment: "checkInLearningEnvironment",
  check_in_update_agreed_goals: "checkInUpdateAgreedGoals",
  is_draft: "isDraft",
  student_name: "learnerName",
  course_name: "courseName",
  version: "version",
  is_annual_review: "isAnnualReview",
  notes: "notes",
  milestones: "milestones",
  milestone_statements: "milestoneStatements",
  learner_engagement: "learnerEngagement",
  language_literacy_numeracy: "languageLiteracyNumeracy",
  goals_comments: "goalsComments",
  professional_development_goals: "professionalDevelopmentGoals",
  agreed_learning_support: "agreedLearningSupport",
  learning_experience_feedback: "learningExperienceFeedback",
  fees: "fees",
  next_visit_date: "nextVisitDate",
  course_code: "courseCode",
  latestFaceToFace: "latestFaceToFace",
  latestAnnualReview: "latestAnnualReview",
  latestGoals: "latestGoals",
};

function reverseKeyValuePairs(
  obj: Record<keyof TEngagementDb, keyof TEngagement>
): Record<keyof TEngagement, keyof TEngagementDb> {
  const reversed: Record<keyof TEngagement, keyof TEngagementDb> = {} as Record<
    keyof TEngagement,
    keyof TEngagementDb
  >;

  Object.keys(obj).forEach((key) => {
    const reversedKey = obj[key as keyof TEngagementDb];
    reversed[reversedKey] = key as keyof TEngagementDb;
  });

  return reversed;
}

export const uiToDBColumnMap: Record<keyof TEngagement, keyof TEngagementDb> =
  reverseKeyValuePairs(dbColumnToUiMap);

export const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
};

export const transformUIToDb = (data: TEngagement) => {
  const transformedData: TEngagementDb = {} as TEngagementDb;
  Object.keys(data).forEach((key) => {
    const dbFieldKey = uiToDBColumnMap[key as keyof TEngagement];
    if (dbFieldKey) {
      // @ts-ignore
      transformedData[dbFieldKey] = data[key];
    }
  });

  return transformedData;
};

export const transformDbToUI = (data: TEngagementDb) => {
  const transformedData: TEngagement = {} as TEngagement;
  Object.keys(data).forEach((key) => {
    const uiFieldKey = dbColumnToUiMap[key as keyof TEngagementDb];
    if (uiFieldKey) {
      // @ts-ignore
      transformedData[uiFieldKey] = data[key];
    }
  });

  return transformedData;
};

export function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeout);
    // @ts-ignore
    timeout = setTimeout(() => func(...args), wait);
  };
}

const fieldsWeightMap: Record<string, number> = {
  assessmentComments: 1,
  awardUsingCapability: 2,
  siteAddress: 3,
  projectDescription: 4,
  learnerInvolvement: 5,
  evaluatorComments: 6,
  "wrap-upStatement": 7,
};

const sortByFieldsByWeight = (fieldNames: string[]) => {
  return fieldNames.sort((a, b) => {
    return fieldsWeightMap[a] - fieldsWeightMap[b];
  });
};

export const getFieldNames = (
  skillLevel: string,
  skillMap: Record<string, string[]>,
  courseCode: string,
  is100Percent?: boolean
) => {
  //L5 kitchen design assignment or workplace skill levels are minor changes
  if (!!skillLevel?.toLowerCase().match(/workplace|assignment/)) {
    return ["assessmentComments"];
  }

  console.log("courseCode, is100Percent", courseCode, is100Percent);
  for (const [key, levels] of Object.entries(skillMap)) {
    if (levels.includes(toSentenceCase(skillLevel))) {
      // for timebeing we are commenting out the below code as we are not using it
      //for L4_V1 we have multiple capstonev1 skillChanges, so we need to return fields only if assignment is 100% complete
      // if (courseCode === "L4_V1" && key === "capstoneV1") {
      //   return is100Percent ? fields[key] || [] : ["assessmentComments"];
      // }
      return fields[key] || ["assessmentComments"];
    }
  }
  return ["assessmentComments"];
};

export const getMileStoneChanges = (
  engagement: TEngagement,
  milestonesFromCanvas: AssignmentGroups = {},
  courseDescription?: string
) => {
  const milestoneStatements: MilestoneChange[] = [];
  const courseCode = engagement?.courseCode || courseDescription || "";
  const skillMap = courseLevelToSkills[courseCode] || {};
  const { totalPercentageDiff, totalCompletionPercentage, ...milestoneItems } =
    engagement.milestones || {};

  const sortedMilestoneAssignmentGroupIds =
    sortByAssignmentGroupNumber(milestoneItems);

  (sortedMilestoneAssignmentGroupIds || []).forEach((assignmentGroupId) => {
    const assignmentGroup = milestoneItems[assignmentGroupId];
    const {
      assignmentGroupName,
      changePercentage,
      changePercentageWeighted,
      assignmentGroupPercentageComplete,
      ...assignments
    } = assignmentGroup;

    const assignmentGroupsFromCanvas = Object.keys(
      milestonesFromCanvas || {}
    ).map((key) => milestonesFromCanvas[key]);

    const assignmentGroupFromCanvas = assignmentGroupsFromCanvas.find(
      (group) => group.assignmentGroupId === Number(assignmentGroupId)
    );

    const sortedAssignmentIds = sortByAssignmentName(assignments);
    (sortedAssignmentIds || []).forEach((assignmentId) => {
      const assignment = assignmentGroup[assignmentId];
      const {
        assignmentName,
        assignmentPercentageComplete,
        changePercentage,
        ...skills
      } = assignment;

      const milestoneStatement: MilestoneChange = {
        assignmentGroupName,
        assignmentName,
        skills: [],
        fields: [],
      };

      const assignmentFromCanvas = assignmentGroupFromCanvas?.assignments?.find(
        (a) => a.id === Number(assignmentId)
      );

      const is100Percent =
        Math.ceil(changePercentage + (assignmentFromCanvas?.grade || 0)) >= 100;

      const sortedSkillIds = sortBySkillName(skills);
      (sortedSkillIds || []).forEach((skillId) => {
        const skill = assignment[skillId];
        const skillName = skill.skillName;
        const skillLevel = skill.changedSkillLevelName;

        // For L4_V2, L4_V1 or L3_V1, statement model needs to display a different mapping of statement for the Fundamentals assignment group
        // Unsupervised is a minor rather than a major
        // Commercially competent requires a CapstoneV2 rather than a CapstoneV1

        const isFundamentalsGroup =
          assignmentGroupFromCanvas?.assignmentGroupName
            ?.toLowerCase()
            .includes("fundamentals");

        const isProfessionalStandardsGroup =
          assignmentGroupFromCanvas?.assignmentGroupName
            ?.toLowerCase()
            .includes("professional standards");

        const isKitchenDesignSkill =
          assignmentFromCanvas?.name
            ?.toLowerCase()
            .match(/31561|31563|31568|31569/) !== null;

        if (skillLevel) {
          if (
            isFundamentalsGroup &&
            (skillLevel === "Unsupervised" ||
              skillLevel === "Commercially competent" ||
              skillLevel.toLowerCase().includes("industry standard"))
          ) {
            if (skillLevel === "Unsupervised") {
              milestoneStatement.fields?.push(...fields["minor"]);
            }
            if (
              skillLevel === "Commercially competent" ||
              skillLevel.toLowerCase().includes("industry standard")
            ) {
              milestoneStatement.fields?.push(...fields["capstoneV2"]);
            }
          } else if (
            isProfessionalStandardsGroup &&
            (skillLevel === "Unsupervised" ||
              skillLevel === "Commercially competent")
          ) {
            if (skillLevel === "Unsupervised") {
              milestoneStatement.fields?.push(...fields["minor"]);
            }
            if (skillLevel === "Commercially competent") {
              milestoneStatement.fields?.push(...fields["capstoneV3"]);
            }
          } else if (isKitchenDesignSkill) {
            if (skillLevel === "Professionally competent") {
              milestoneStatement.fields?.push(...fields["capstoneV1"]);
            } else {
              milestoneStatement.fields?.push(...fields["minor"]);
            }
          } else {
            const fieldNames = getFieldNames(
              skillLevel,
              skillMap,
              courseCode,
              is100Percent
            );

            milestoneStatement.fields?.push(...fieldNames);
          }
          milestoneStatement.skills.push({
            skillName,
            skillLevel,
          });
        }
      });

      if (milestoneStatement.fields?.length) {
        milestoneStatement.assignmentId = assignmentId;
        milestoneStatement.fields = sortByFieldsByWeight(
          uniq(milestoneStatement.fields)
        );
        milestoneStatements.push(milestoneStatement);
      }
    });
  });
  return milestoneStatements;
};

export const camelToPascalWithSpaces = (camelCaseStr: string) => {
  if (!camelCaseStr) return "";

  const words = camelCaseStr.match(/[A-Z][a-z]+|[a-z]+/g);

  if (!words) return "";

  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const toSentenceCase = (str: string) => {
  if (!str) return str;
  str = str.replace(/^\s+/, "");

  const firstAlphaIndex = str.search(/[a-zA-Z]/);
  if (firstAlphaIndex === -1) return str; // No alphabetic character found

  let result =
    str.slice(0, firstAlphaIndex) +
    str[firstAlphaIndex].toUpperCase() +
    str.slice(firstAlphaIndex + 1).toLowerCase();

  // Capitalize the first letter after each '- ' or '— ' (hyphen or em dash followed by a space)
  result = result.replace(
    /([-—]) ([a-zA-Z])/g,
    (_, p1, p2) => `${p1} ${p2.toUpperCase()}`
  );

  return result;
};

export const removeGateway = (str: string) => {
  if (!str) return str;
  const regex = /\(.*gateway.*\)/gi;

  return str.replace(regex, "");
};

const sortByAssignmentGroupNumber = (assignmentGroups: Record<string, any>) =>
  Object.keys(assignmentGroups).sort((a, b) => Number(a) - Number(b));

const sortByAssignmentName = (assignments: Record<string, any>) =>
  Object.keys(assignments).sort((a, b) => {
    let assignmentNumberA =
      assignments[a].assignmentName?.match(/(\d+)?(.*)/)[1];
    let assignmentNumberB =
      assignments[b].assignmentName?.match(/(\d+)?(.*)/)[1];
    assignmentNumberA = isNaN(Number(assignmentNumberA))
      ? 0
      : Number(assignmentNumberA);
    assignmentNumberB = isNaN(Number(assignmentNumberB))
      ? 0
      : Number(assignmentNumberB);
    return assignmentNumberA - assignmentNumberB;
  });

export const sortBySkillName = (skills: Record<string, any>) =>
  Object.keys(skills).sort((a, b) => {
    const skillNumberA = parseInt(skills[a].skillName.match(/(\d+)?(.*)/)[1]);
    const skillNumberB = parseInt(skills[b].skillName.match(/(\d+)?(.*)/)[1]);
    return Number(skillNumberA) - Number(skillNumberB);
  });

// Utility function to stop unwanted event propagation
export const stopPropagation = (
  handler?: (
    e:
      | React.MouseEvent<EventTarget>
      | React.KeyboardEvent<EventTarget>
      | React.TouchEvent<EventTarget>
  ) => void
) => {
  return (
    e:
      | React.MouseEvent<EventTarget>
      | React.KeyboardEvent<EventTarget>
      | React.TouchEvent<EventTarget>
  ) => {
    if (e.type === "keydown") {
      const key = (e as React.KeyboardEvent<EventTarget>).key;
      if (key !== "Enter" && key !== " ") {
        return;
      }
    }
    e.stopPropagation();
    e.preventDefault();

    if (handler) {
      handler(e);
    }
  };
};

export const getGroupColor = (name: string) =>
  ({
    fundamentals: "#4d56a5",
    "preliminary work": "#fcad1c",
    "support structures": "#e24125",
    "frames and structures": "#bc273a",
    "exterior envelope": "#431d46",
    "interior lining and finishing": "#00a652",
    "professional standards": "#9e0006",
    "produce quotations": "#8155ab",
    "install and glass windows and doors": "#8155ab",
    "repair and maintain windows and doors": "#ff8130",
    "documents and materials": "#0155ae",
    "non-standard aluminium joinery production": "#75c448",
    "materials, methods and tools": "#75c448",
    machining: "#8155ab",
    fabrication: "#ff8130",
    "cnc machinery": "#8155ab",
    preparation: "#16813a",
    "safety and quality control": "#ffd041",
    "customer serive and teamwork": "#75c448",
    materials: "#ff8130",
    "repair and replacement": "#0155ae",
    "flat glass": "#fa2d18",
    preliminary: "#fcad1c",
    "set-out": "#fa2d18",
    "footings and slab": "#4d56a5",
    formwork: "#007e3d",
    reinforcing: "#007e3d",
    concrete: "#4d56a5",
    "pre-cast units": "#015aaa",
    "pre-pour": "#015aaa",
    "placing & finishing": "#007e3d",
    "post hardened": "#e41f25",
    "other concrete operations": "#fad320",
    "general operations": "#007e3e",
    batching: "#fa2d18",
    dispatching: "#007e3d",
    testing: "#ff8200",
    "sawing & drilling": "#11803b",
    "plant & equipment": "#fa2d18",
    "unrecorded tasks": "#7b2d26",
    "plans and quantities": "#ffd041",
    "safety, quality and standards": "#7b2d26",
    "tools and equipment": "#ff8130",
    substrates: "#0155ae",
    "resin flooring": "#fa2d18",
    safety: "#4d56a5",
    "preliminary work skills": "#fcad1c",
    "footings and slab on ground skills": "#4d56a5",
    "building legislation": "#4d56a5",
    "pile foundations": "#007e3d",
    ordering: "#4d56a5",
    communication: "#933d34",
    "cutting glass": "#0155ae",
    "toughen and heat strengthen glass": "#16813a",
    "laminated glass": "#8155ab",
    "screen printed glass": "#ff8130",
    machinery: "#75c448",
    "residental glazing": "#fa2d18",
    "commerical glazing": "#16813a",
    "frameless glazing": "#ff8130",
    "specialist models and moulds": "#ff8130",
    "running moulds": "#fa2d18",
    casting: "#16813a",
    fixing: "#16813a",
    finishing: "#fa2d18",
    "fibrous plaster installation": "#ff8130",
    "suspended ceilings": "#fa2d18",
    "health and safety, codes of practice and industy standards": "#ffd041",
    "tools, materials and manufacturing methods": "#8155ab",
    "drawings and specifications": "#0155ae",
    cabinetry: "#ff8130",
    "doors and windows": "#00b8d3",
    "timber stairs": "#0155ae",
    "design development": "#fa2d18",
    "kitchen installation": "#fa2d18",
    "products and materials": "#16813a",
    "painting and decorating": "#fa2d18",
    "specialised coatings": "#00b8d3",
    "wall coverings": "#fcaf17",
    "specialised spraying": "#ff8130",
    "conservation & preservation": "#75c448",
    "construction stonemasonry": "#16813a",
    "monumental masonry": "#16813a",
    "ferro- cement tank manufacture": "#16813a",
    "proprietary plaster cladding systems": "#16813a",
    "refractory installation": "#16813a",
    tiling: "#16813a",
    "level 1 unit standards student resources": "#8ad3e9",
    "level 2 unit standards student resources": "#00a8d5",
    "level 3 unit standards student resources": "#006e77",
    "solid plastering": "#8ad3e9",
    "banker masonry": "#8ad3e9",
    "natural stone fixtures and fittings": "#8ad3e9",
    "brick laying": "#ff8200",
    "block laying": "#f69d81",
    "alternative claddings": "#fcaf17",
    "workplace fundamentals": "#4d56a5",
    "glass processing": "#8ad3e9",
    "glass knowledge": "#005577",
    "calculate, order and move glass": "#00a8d5",
    "insulating glass units": "#414042",
    "toughened and heat strengthened glass": "#6bbf4e",
    wallcoverings: "#fcaf17",
    "customer service & teamwork": "#00a8d5",
    "specialised finishes": "#ff8200",
    "formwork up to 1.2 metres skills": "#ff8200",
    "place and finish concrete": "#414042",
    refractory: "#ff8200",
    "propriety partitions": "#fcaf17",
    "specialist moulds and models": "#fcaf17",
    "health and safety, codes of practice and industry standards": "#414042",
    "conservation and preservation": "#8ad3e9",
    "materials and methods": "#ed1c24",
    "floor planning and design": "#d35f58",
    "carpet installation": "#9e0006",
    "timber overlay": "#414042",
    "fine sand and finishes": "#787e7d",
    "resilient flooring": "#005577",
    "bonded carpet": "#007e3d",
    "core carpet": "#636366",
    "core resilient": "#fad320",
    "conventional carpet": "#6bbe4e",
    "commercial resilient flooring": "#f5862e",
    "residential resilient flooring": "#bd9705",
    estimation: "#d35f58",
    "site assessment": "#9e0006",
    "resin core": "#ed1c24",
    "trowel applied resin mortar": "#d35f58",
    "flow applied resin mortar": "#ed1c24",
    "bonded installation": "#9e0006",
    "fine floor sands and finishes": "#ed1c24",
    "trusses and component cutting": "#821f6d",
    "tools and machinery": "#00a8d5",
    "timber knowledge and making up orders": "#e386c7",
    "setting out and assembling wall frames": "#520046",
    "packing and stacking": "#414042",
    "drawing and calculations": "#fcaf17",
    "first aid": "#4d56a5",
    roofs: "#f05e30",
    "compliance documentation": "#7c7d80",
    structures: "#ed1c24",
    "introductory skills": "#e24125",
    "timber and materials": "#d35f58",
    walls: "#9e0006",
    "small buildings": "#e386c7",
    "wall frame manufacture strand": "#bc273a",
    "roof truss manufacture strand": "#e24125",
    "commercial standard": "#009ec9",
    "residential glazing": "#414042",
    "commercial glazing": "#505957",
    igus: "#009ec9",
    cnc: "#00e2e3",
    "interpret plans and specifications for insulation installation": "#6bbe4e",
    "demonstate knowledge of installing wall, ceiling, and underfloor insulation, and ground vapour barriers":
      "#bbdca8",
    "demonstrate knowledge of insulation products": "#216734",
    "install wall, ceiling, and underfloor insulation, and groud vapour barriers":
      "#00a652",
    "proprietary partitions": "#00a652",
    hpl: "#9e0006",
    installation: "#9e0006",
    "communication and teamwork": "#d35f58",
    "kitchen design": "#e386c7",
    "install kitchens": "#ed1c24",
    "trowel trades preparation": "#005577",
    "trowel trades plaster": "#8ad3e9",
    "trowel trades mix": "#414042",
    "trowel trades place and finish concrete": "#ed1c24",
    "trowel trades materials": "#821f6d",
    "concrete operations": "#414042",
    "decorative concrete": "#f05e30",
    "pre pour": "#e386c7",
    "flat floor": "#ff8200",
    "confined space entry": "#bbdca8",
    moulds: "#216734",
    "moving precast concrete": "#00a652",
    "precast tank manufacture": "#216734",
    "confined spaces": "#bbdca8",
    "precast elements": "#b0d9a7",
    "working safely, customer service and teamwork": "#00a8d5",
    "codes of practice, plans and specifications": "#8ad3e9",
    "tensile testing optional": "#8e58a4",
    "specified concrete finishes": "#f69d81",
    "concrete production manufacture": "#f05e30",
    "artefacts for experienced tradespeople": "#787e7d",
    "industry standard": "#a7a9ac",
    "concrete construction": "#f69d81",
    "concrete construction skills fundamentals": "#f69d81",
    "concrete testing": "#8e58a4",
    "post-tensioned concrete": "#414042",
    "premanufactured elements": "#a7a9ac",
    "off-job training courses": "#a7a9ac",
    "precast concrete manufacture": "#ff8200",
    "29677 - follow safe workplace practices, and contribute to a health and safety culture, in a bcats environment":
      "#4d56a5",
    "29678 - demonstrate knowledge of, select, and use materials for a stage 3 bcats project":
      "#821f6c",
    "29680 - communicate and work collaboratively in a stage 3 bcats project":
      "#ff8200",
    "29681 - measure and calculate for a stage 3 bcats project": "#6bbe4e",
    "29682 - select, use, and maintain tools, equipment and machinery for a stage 3 bcats project":
      "#006737",
    "29683 - incorporate other building, construction and allied trades into a stage 3 bcats project schedule":
      "#ed1c24",
    "work placements": "#fcaf17",
    "12927 - identify, select, maintain, and use hand tools for bcats projects":
      "#006737",
    "24354 - demonstrate knowledge of and apply safe working practices in a bcats environment":
      "#4d56a5",
    "24357 - receive instructions and communicate information in relation to bcats projects":
      "#ff8200",
    "24360 - demonstrate knowledge of timber and other construction materials used in bcats projects":
      "#821f6c",
    "31511 - demonstrate knowledge of bcats industries": "#ed1c24",
    "optional 22607 - read and interpret plans, working drawings and specifications for bcats projects":
      "#00a8d5",
    "optional 24350 - identify, select, use and maintain portable power tools for bcats projects":
      "#b0d9a7",
    "optional 24353 - demonstrate knowledge of and create sketches and drawings for bcats projects":
      "#8ad3e9",
    "optional 24361 - apply mathematical processes to bcats projects":
      "#f05e30",
    "work placement diary": "#fcaf17",
    "12997 - demonstrate knowledge of working safely in construction":
      "#4d56a5",
    "13002 - demonstrate knowledge of timber use in construction": "#821f6c",
    "24362 - demonstrate knowledge of documentation, working drawings, and specifications for building work":
      "#00a8d5",
    "24378 - make calculations for carpentry": "#6bbe4e",
    "32443 - demonstrate knowledge of tools and equipment used for carpentry":
      "#006737",
    "supervising a project": "#6bbe4e",
    "leading your team": "#005577",
    sustainability: "#216734",
    "supervising technical work": "#009ec9",
    core: "#4d56a5",
    "kitchen design strand": "#216734",
  }[name.toLowerCase()] || "#00709c");
