import { OLD_CARPENTRY_COURSES } from "./types";
import assessmentPrompts from "./assessment-prompts.json";

export const getAssessmentPrompt = (
  courseId: number,
  assignmentNumber: string,
  description: string,
  defaultAssessmentPrompt: string
) => {
  if (!OLD_CARPENTRY_COURSES.includes(courseId)) return defaultAssessmentPrompt;

  const standardiseStr = (str: string) =>
    str.toLowerCase().replace(/[^0-9a-z]*/g, "");

  return (
    assessmentPrompts.find(
      (ap) =>
        ap.assignment.match(/(\d+)(\s|-)/)?.[1] === assignmentNumber &&
        standardiseStr(ap.description) === standardiseStr(description)
    )?.long_description || defaultAssessmentPrompt
  );
};
