import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { getCookie } from "./utils";

const reactPlugin = new ReactPlugin();

const instrumentationKey = import.meta.env.VITE_APP_INSIGHTS_KEY || "TestKey";

const appInsights = new ApplicationInsights({
  config: {
    connectionString: `instrumentationKey=${instrumentationKey}`,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
});

appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || {};
  env.tags["ai.cloud.role"] = "<app-role-frontend>";
  //custom props
  env.data = env.data || {};
  env.data["taId"] = getCookie("SID") || "";
  env.data["swIntalledDate"] =
    window.localStorage.getItem("swInstalledAt") || "Not Installed yet";
});
export { reactPlugin, appInsights };
