import {
  Field,
  InfoLabel,
  LabelProps,
  Textarea as TextareaBase,
  TextareaOnChangeData,
  TextareaProps,
} from "@fluentui/react-components";
import styles from "./textArea.module.scss";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import debounce from "lodash/debounce";
import { AppContext, AppDispatchContext } from "../../AppContext";
import { uiToDBColumnMap } from "../../utils";
import { TEngagement } from "../../types";
import { CaretDownRightFilled } from "@fluentui/react-icons";

interface Props {
  id: string;
  label: string;
  labelSuffix?: string;
  extraLabel?: string;
  helpText?: string;
  required?: boolean;
  info?: React.ReactNode;
  expandDefault?: boolean;
  onChange?: (value: string) => void;
  hasError?: boolean;
}

export const TextArea: React.FC<Props & TextareaProps> = ({
  expandDefault = false,
  ...props
}) => {
  const { label, extraLabel, helpText, id, required, info } = props;
  const [value, setValue] = useState<string>(props.value || "");
  const [dirty, setDirty] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { engagement, errors = [] } = useContext(AppContext);
  const appDispatch = useContext(AppDispatchContext);
  const expandDefaultHeight = 300;

  const debouncedChangeHandler = useCallback(
    debounce(async (value, id) => {
      if (props.onChange) {
        props?.onChange(value);
        return;
      }

      const dbKey = uiToDBColumnMap[id as keyof TEngagement];
      appDispatch({
        type: "SET_ENGAGEMENT",
        payload: { [id]: value },
      });

      await fetch(`/api/engagements?engagementId=${engagement?.engagementId}`, {
        method: "PUT",
        body: JSON.stringify({
          key: dbKey,
          value: value,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
    }, 1000),
    [engagement]
  );

  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea && expandDefault) {
      textarea.style.height = `${expandDefaultHeight}px`;
      textarea.style.minHeight = `${expandDefaultHeight}px`;
    }
  }, [expandDefaultHeight]);

  const onLoad: TextareaProps["onLoad"] = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea && expandDefaultHeight) {
      textarea.style.height = `${expandDefaultHeight}px`;
    }
  }, [expandDefaultHeight]);

  const onChange: TextareaProps["onChange"] = useCallback(
    (_: ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => {
      setValue(data.value);

      setDirty(true);
      debouncedChangeHandler(data.value, id);
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
    [debouncedChangeHandler, id]
  );

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  const hasValidationError =
    errors.find((error) => error.fieldId === id) && !value;

  const hasError = (props.required && dirty && !value) || hasValidationError;

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    document.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
    document.addEventListener("touchend", handleTouchEnd);
  };

  const handleMouseMove = (e: MouseEvent) => {
    requestAnimationFrame(() => {
      if (textareaRef.current) {
        const newHeight =
          e.clientY - textareaRef.current.getBoundingClientRect().top;
        textareaRef.current.style.height = `${newHeight}px`;
      }
    });
  };

  const handleTouchMove = (e: TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    requestAnimationFrame(() => {
      if (textareaRef.current) {
        const newHeight =
          e.touches[0].clientY -
          textareaRef.current.getBoundingClientRect().top;
        textareaRef.current.style.height = `${newHeight}px`;
      }
    });
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleTouchEnd = () => {
    document.removeEventListener("touchmove", handleTouchMove);
    document.removeEventListener("touchend", handleTouchEnd);
  };

  return (
    <Field
      validationState={hasError ? "error" : "none"}
      // @ts-ignore
      label={{
        children: (_: unknown, slotProps: LabelProps) => (
          <InfoLabel
            {...slotProps}
            // @ts-ignore
            infoButton={info ? { size: "large", className: {} } : undefined}
            info={info || undefined}
            name={id}
            htmlFor={id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <>
              <div className={styles.labelContainer}>
                <span className={styles.label}>{label}</span>
                {required ? (
                  <span className={styles.required}>(required)</span>
                ) : (
                  <span className={styles.notRequired}>(optional)</span>
                )}
                {extraLabel ? (
                  <span className={styles.extraLabel}>({extraLabel})</span>
                ) : (
                  <></>
                )}
              </div>
              {helpText ? (
                <span
                  className={
                    !engagement?.isDraft ? styles.hide : styles.helpText
                  }
                >
                  {helpText}
                </span>
              ) : (
                <></>
              )}
            </>
          </InfoLabel>
        ),
        className: styles.fieldLabel,
        for: id,
      }}
      className={styles.fieldContainer}
    >
      <TextareaBase
        ref={textareaRef}
        resize="vertical"
        className={(() => {
          if (!engagement?.isDraft) return styles.textAreaDisabled;
          if (hasError) return styles.textAreaError;
          return styles.textArea;
        })()}
        id={id}
        appearance="filled-lighter"
        value={value}
        onChange={onChange}
        onLoad={onLoad}
        disabled={!engagement?.isDraft}
        aria-labelledby={id}
      />
      <div
        className={styles.dragHandle}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <CaretDownRightFilled />
      </div>
    </Field>
  );
};
