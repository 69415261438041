import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./nextVisit.module.scss";
import DatePicker from "../datePicker";
import { Button } from "../button";
import { AppContext, AppDispatchContext } from "../../AppContext";
import { AssignmentGroups, TEmployer, TEngagement } from "../../types";
import { Link, useNavigate } from "react-router-dom";
import { Info32Filled } from "@fluentui/react-icons";
import { string } from "yup";
import {
  camelToPascalWithSpaces,
  getMileStoneChanges,
  toSentenceCase,
} from "../../utils";
import { fieldInfo } from "../../constants";
import { Checkbox } from "../checkbox";
import dayjs from "dayjs";
import { Spinner } from "@fluentui/react-components";

type EngagementClone = TEngagement & Record<string, any>;

const NextVisit: React.FC = () => {
  const { engagement, schema, milestones, learners, online } =
    useContext(AppContext);
  const [nextVisitDate, setNextVisitDate] = React.useState<Date | null>(null);
  const [errors, setErrors] = useState<
    { data: string; link: string; fieldId: string }[]
  >([]);
  const [assignmentGroupsFromCanvas, setAssignmentGroupsFromCanvas] =
    React.useState<AssignmentGroups>({});

  const [employers, setEmployers] = useState<TEmployer[]>([]);
  const [selectedEmployers, setSelectedEmployers] = useState<TEmployer[]>([]);
  const [isLoadingEmployers, setIsLoadingEmployers] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isCompleting, setIsCompleting] = useState<boolean>(false);

  const [valid, setValid] = useState(false);
  const navigate = useNavigate();

  const dispatch = useContext(AppDispatchContext);

  const getLink = (pageName: string, fieldId: string) => {
    switch (pageName) {
      case "Details":
        return `/engagement/${engagement.engagementId}/details#${fieldId}`;
      case "Goals":
        return `/engagement/${engagement.engagementId}/goals#${fieldId}`;
      case "Progress":
        return `/engagement/${engagement.engagementId}/progress#${fieldId}`;
      case "Milestones":
        return `/engagement/${engagement.engagementId}/progress#${fieldId}`;
      case "Annual Review":
        return `/engagement/${engagement.engagementId}/review#${fieldId}`;
      case "Next Visit":
        return `/engagement/${engagement.engagementId}/next-visit#${fieldId}`;
      default:
        return "/";
    }
  };

  const validate = useCallback(async () => {
    if (!engagement?.isDraft) {
      return;
    }

    const engagementClone = { ...engagement } as EngagementClone;
    if (!Object.keys(engagementClone).length) {
      return;
    }
    const milestoneStatements = engagementClone.milestoneStatements || {};
    Object.keys(milestoneStatements).forEach((assignmentId) => {
      const milestoneStatement = milestoneStatements[assignmentId];
      Object.keys(milestoneStatement).forEach((filedName) => {
        const fieldValue = milestoneStatement[filedName];
        engagementClone[`${assignmentId}_${filedName}`] = fieldValue;
      });
    });

    try {
      setErrors([]);
      if (!!nextVisitDate) {
        engagementClone.nextVisitDate = dayjs(nextVisitDate)
          .valueOf()
          .toString();
      }
      await schema.validate(
        { ...engagementClone, isCompleting },
        { abortEarly: false }
      );
      setValid(true);
    } catch (err) {
      // @ts-ignore
      const errors: string[] = err.errors || [];
      setValid(false);
      const formattedErrors = errors.map((error: string) => {
        const [errorMessage, fieldId] = error.split("#");
        const pageName = error.split(":")[0];
        const link = getLink(pageName, fieldId);
        return {
          data: errorMessage,
          link,
          fieldId,
          pageName,
        };
      });

      dispatch({ type: "SET_ERRORS", payload: formattedErrors });
      setErrors(formattedErrors);
    }
  }, [engagement, isCompleting, nextVisitDate, schema]);

  const updateNextVisitDate = useCallback(
    async (date: Date) => {
      setNextVisitDate(date);
      validate();

      dispatch({
        type: "SET_ENGAGEMENT",
        payload: {
          nextVisitDate: dayjs(date).valueOf().toString(),
        },
      });
      if (!date) return;
      await updateEngagement(
        "next_visit_date",
        dayjs(date).valueOf().toString()
      );
    },
    [engagement]
  );

  const updateEngagement = async (key: string, value: any) => {
    await fetch(`/api/engagements?engagementId=${engagement?.engagementId}`, {
      method: "PUT",
      body: JSON.stringify({
        key,
        value,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
  };

  useEffect(() => {
    if (!engagement.courseId || !engagement.learnerId || !online) return;

    const getEmployers = async () => {
      setIsLoadingEmployers(true);

      const emps = await fetch(
        `/api/employers?courseId=${engagement.courseId}&studentId=${engagement.learnerId}`
      );

      if (emps.ok) {
        setEmployers(await emps.json());
        setSelectedEmployers([]);
      }

      setIsLoadingEmployers(false);
    };

    getEmployers();
  }, [engagement.courseId, engagement.learnerId, online]);

  useEffect(() => {
    const initialNextVisitDateTime = engagement?.nextVisitDate
      ? new Date(Number(engagement.nextVisitDate))
      : null;
    setNextVisitDate(initialNextVisitDateTime);
  }, [engagement]);

  useEffect(() => {
    if (!engagement.learnerId || !engagement.courseId) {
      return;
    }

    const milestone = milestones?.find(
      (m) => m.engagementId === engagement.engagementId
    );

    const hasAssignmentGroups =
      Object.keys(milestone?.assignmentGroups || {}).length > 0;

    if (hasAssignmentGroups && milestone) {
      const { assignmentGroups } = milestone;
      setAssignmentGroupsFromCanvas(assignmentGroups);

      const totalCanvasPercentage = Object.keys(
        assignmentGroups
      ).reduce<number>(
        (total, groupName) => total + assignmentGroups[groupName].gradeWeighted,
        0
      );

      const totalChangePercentage =
        engagement.milestones?.totalPercentageDiff || 0;

      const overallProgress =
        totalCanvasPercentage + Math.ceil(totalChangePercentage);
      setIsCompleting(overallProgress >= 100);
      return;
    }

    const fetchMilestones = async () => {
      try {
        const response = await fetch(
          `/api/milestones?studentId=${engagement.learnerId}&courseId=${engagement.courseId}&engagementId=${engagement.engagementId}`
        );
        if (!response.ok) {
          throw new Error(`Error fetching milestones: ${response.status}`);
        }
        const data = await response.json();

        dispatch({
          type: "SET_MILESTONE",
          payload: {
            engagementId: engagement?.engagementId,
            assignmentGroups: data,
          },
        });
        setAssignmentGroupsFromCanvas(data);
      } catch (error) {
        console.error("Error fetching engagement details: ", error);
      }
    };

    fetchMilestones();
  }, [milestones, engagement.learnerId, engagement.courseId]);

  // Adding Canvas completion percentage to the engagement object
  useEffect(() => {
    (async () => {
      const milestones = engagement.milestones || {};
      if (
        Object.keys(assignmentGroupsFromCanvas).length === 0 ||
        Object.keys(milestones).length === 0
      ) {
        return;
      }

      const totalCanvasPercentage = Object.keys(
        assignmentGroupsFromCanvas || {}
      ).reduce<number>(
        (total, groupName) =>
          total + assignmentGroupsFromCanvas[groupName]?.gradeWeighted,
        0
      );

      if (typeof milestones.totalCompletionPercentage === "undefined") {
        milestones.totalCompletionPercentage = totalCanvasPercentage;
        const {
          totalPercentageDiff,
          totalCompletionPercentage,
          ...assignmentGroups
        } = milestones;
        Object.keys(assignmentGroups).forEach((assignmentGroupId) => {
          const assignmentGroupNameCanvas =
            Object.keys(assignmentGroupsFromCanvas).find(
              (assignmentGroupName) => {
                const assignmentGroup =
                  assignmentGroupsFromCanvas[assignmentGroupName];
                return (
                  assignmentGroup.assignmentGroupId ===
                  Number(assignmentGroupId)
                );
              }
            ) || "";

          const assignmentGroupFromCanvas =
            assignmentGroupsFromCanvas[assignmentGroupNameCanvas];

          const {
            changePercentage,
            assignmentGroupName,
            changePercentageWeighted,
            ...assignments
          } = assignmentGroups[assignmentGroupId];

          const assignmentsCount = Object.keys(assignments).length;
          const assignmentGroupPercentageComplete = Math.floor(
            assignmentGroupFromCanvas?.grade / (assignmentsCount || 1)
          );
          milestones[assignmentGroupId].assignmentGroupPercentageComplete =
            assignmentGroupPercentageComplete;

          Object.keys(assignments).forEach((assignmentId) => {
            const assignmentFromCanvas = assignmentGroupsFromCanvas[
              assignmentGroupId
            ]?.assignments.find((a) => a.id === Number(assignmentId));

            const assignmentPercentageComplete = Math.floor(
              assignmentFromCanvas?.grade || 0
            );
            milestones[assignmentGroupId][
              assignmentId
            ].assignmentPercentageComplete = assignmentPercentageComplete;
          });
        });
        await updateEngagement("milestones", milestones);
      }
    })();
  }, [
    engagement.engagementId,
    engagement.milestones,
    assignmentGroupsFromCanvas,
  ]);

  // Adding milestone fields validations to yup schema
  useEffect(() => {
    dispatch({
      type: "RESET_SCHEMA",
    });
    dispatch({
      type: "RESET_ERRORS",
    });

    const learner = learners.find(
      (l) =>
        l.learnerId === engagement?.learnerId &&
        l.courseId === engagement?.courseId
    );

    const milestoneChanges = getMileStoneChanges(
      engagement,
      assignmentGroupsFromCanvas || {},
      learner?.courseDescription
    );

    milestoneChanges.forEach((milestoneChange) => {
      const {
        assignmentName,
        assignmentId = "",

        fields: fieldNames = [],
      } = milestoneChange;
      const milestoneStatements = engagement.milestoneStatements || {};
      const milestoneStatement = milestoneStatements[assignmentId] || {};
      const hasAwardUsingCapability =
        milestoneStatement["awardUsingCapability"];

      fieldNames.forEach((fieldName) => {
        const fieldData = fieldInfo[fieldName];
        let isRequired = fieldData?.required;
        if (
          hasAwardUsingCapability &&
          (fieldName === "siteAddress" ||
            fieldName === "projectDescription" ||
            fieldName === "learnerInvolvement")
        ) {
          isRequired = false;
        }

        const filedNamePascalCase = toSentenceCase(
          camelToPascalWithSpaces(fieldName)
        );

        if (isRequired) {
          dispatch({
            type: "ADD_FIELD_TO_SCHEMA",
            payload: {
              [`${assignmentId}_${fieldName}`]: string().required(
                `Milestones: ${assignmentName} - ${filedNamePascalCase}#${assignmentId}_${fieldName}`
              ),
            },
          });
        }
      });
    });
  }, [
    engagement.engagementId,
    engagement.milestones,
    learners,
    assignmentGroupsFromCanvas,
  ]);

  useEffect(() => {
    validate();
  }, [engagement.engagementId, schema, isCompleting, nextVisitDate]);

  function returnToDashboard() {
    navigate("/");
  }

  const submitEngagement = async () => {
    setIsSubmitting(true);
    const res = await fetch(
      `/api/engagements?engagementId=${engagement.engagementId}`,
      {
        method: "POST",
        body: JSON.stringify({
          employers: selectedEmployers,
        }),
      }
    );
    if (res.ok) {
      setIsSubmitting(false);
      dispatch({ type: "RESET_ENGAGEMENT" });
      navigate("/submission");
      return;
    }
    setIsSubmitting(false);
  };

  const isToday = dayjs(nextVisitDate).isSame(dayjs(), "day");
  const minTime = dayjs(new Date(0)).set("hour", 6).set("minute", 0).toDate();

  return (
    <div className={styles.root}>
      <h1>Next visit</h1>
      {isCompleting && (
        <div>
          <p>
            <strong>{engagement.learnerName}</strong>
            {" has successfully completed a qualification in: "}
            <strong>{engagement.courseName + "!"}</strong>
          </p>
        </div>
      )}
      <div className={styles.datePicker}>
        <DatePicker
          selected={nextVisitDate}
          onChange={updateNextVisitDate}
          label="Next visit date"
          required={!isCompleting}
          id="nextVisitDate"
          disabled={!engagement?.isDraft}
          minDate={new Date()}
          minTime={isToday || !nextVisitDate ? new Date() : minTime}
        />
      </div>
      {errors.length > 0 && (
        <div className={styles.errorContainer}>
          <Info32Filled />
          <div>
            <label>
              The following fields must be completed before the engagement can
              be submitted
            </label>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>
                  <Link to={error.link}>{error.data}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {isLoadingEmployers || employers.length ? (
        <div className={styles.emailCheckboxContainer}>
          <div className={styles.emailCheckbox}>
            <div className={styles.labelContainer}>
              <label>Send visit report to employer</label>
              {isLoadingEmployers ? (
                <span>
                  <Spinner size="tiny" />
                </span>
              ) : null}
            </div>
          </div>
          {isLoadingEmployers
            ? null
            : employers.map(({ name, email }, idx) => (
                <div className={styles.emailCheckbox} id={String(idx)}>
                  <div className={styles.labelContainer}>
                    <span>
                      {email} ({name})
                    </span>
                  </div>
                  <Checkbox
                    id={email}
                    className={styles.checkbox}
                    checked={Boolean(
                      selectedEmployers.find(
                        (se) => se.email === email && se.name === name
                      )
                    )}
                    onChange={(_, data) => {
                      setSelectedEmployers((ses) => {
                        const employerInSelectedList = ses.find(
                          (se) => se.email === email && se.name === name
                        );

                        if (Boolean(data.checked) && !employerInSelectedList) {
                          return [...ses, { name, email }];
                        } else if (
                          !Boolean(data.checked) &&
                          employerInSelectedList
                        ) {
                          return ses.filter(
                            (se) => se.email !== email && se.name !== name
                          );
                        }

                        return ses;
                      });
                    }}
                    disabled={
                      !engagement?.isDraft ||
                      (!/\@bcito\.org\.nz/.test(email) &&
                        window.location.hostname !== "hono.bcito.org.nz")
                    }
                  />
                </div>
              ))}
        </div>
      ) : null}
      <div className={styles.submissionInfo}>
        <div className={styles.submissionInfoTitle}>
          <h4>Submitting engagement for:</h4>
        </div>
        <span className={styles.submissionInfoLearner}>
          {engagement.learnerName}
        </span>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          content="Return to dashboard"
          className={styles.draftButton}
          onClick={returnToDashboard}
        />
        <Button
          disabled={
            !valid ||
            !engagement?.isDraft ||
            isSubmitting ||
            !online ||
            new Date(Number(engagement.scheduledTime || 0)).getTime() >
              Date.now()
          }
          className={styles.submitEngagement}
          onClick={submitEngagement}
          icon={isSubmitting ? <Spinner size="tiny" /> : null}
          content="Submit engagement"
        />
      </div>
    </div>
  );
};

export default NextVisit;
