import React, { ChangeEvent, useCallback, useContext, useState } from "react";
import { CommentAddFilled, CommentAddRegular } from "@fluentui/react-icons";
import styles from "./goal.module.scss";
import { MilestoneChange } from "../../types";
import { TextArea } from "../textArea";
import { AppContext, AppDispatchContext } from "../../AppContext";
import {
  getGroupColor,
  removeGateway,
  stopPropagation,
  toSentenceCase,
} from "../../utils";

export const Goal: React.FC<MilestoneChange> = (props) => {
  const [open, setOpen] = useState(false);
  const { engagement } = useContext(AppContext);
  const appDispatch = useContext(AppDispatchContext);
  const [assignmentNumber, assignmentName] =
    props.assignmentName.split(/\s-\s/);
  const existingEngagementGoalComments = engagement?.goalsComments || {};
  const { assignmentId = "", assignmentGroupId = "" } = props;

  const existingAssignmentGoalComment =
    existingEngagementGoalComments[assignmentGroupId]?.[assignmentId];

  const handleOnchange = useCallback(
    async (value: string | ChangeEvent<HTMLTextAreaElement>) => {
      const updatedGoalComments = {
        ...existingEngagementGoalComments,
        [assignmentGroupId]: {
          assignmentGroupName: props.assignmentGroupName,
          ...existingEngagementGoalComments[assignmentGroupId],
          [assignmentId]: {
            assignmentName: props.assignmentName,
            comments: value,
          },
        },
      };

      try {
        appDispatch({
          type: "SET_ENGAGEMENT",
          payload: {
            goalsComments: updatedGoalComments,
          },
        });

        await fetch(
          `/api/engagements?engagementId=${engagement?.engagementId}`,
          {
            method: "PUT",
            body: JSON.stringify({
              key: "goals_comments",
              value: JSON.stringify(updatedGoalComments),
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );
      } catch (error) {
        console.error("Error updating milestone statements", error);
      }
    },
    [props.assignmentId, engagement]
  );

  // Manually setting focus to opened textarea
  const addGoalComment = () => {
    setOpen(true);
    setTimeout(() => {
      const textarea = document.getElementById(`${assignmentNumber}`);
      if (textarea) {
        textarea.focus();
      }
    }, 0);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div>
          <div>{toSentenceCase(props.assignmentGroupName as string)}</div>
          <div className={styles.unitStandardName}>
            {toSentenceCase(removeGateway(assignmentName))}
            <span className={styles.unitStandardNumber}>
              ({assignmentNumber})
            </span>
          </div>
        </div>
        {open ? (
          <CommentAddFilled
            className={styles.commentIcon}
            role="button"
            tabIndex={0}
            aria-label="Close comments"
            onClick={stopPropagation(() => setOpen(false))}
            onKeyDown={stopPropagation(() => setOpen(false))}
            onMouseDown={stopPropagation()}
          />
        ) : (
          <CommentAddRegular
            className={styles.commentIcon}
            role="button"
            tabIndex={0}
            aria-label="Add comments"
            onClick={stopPropagation(addGoalComment)}
            onKeyDown={stopPropagation(addGoalComment)}
            onMouseDown={stopPropagation()}
          />
        )}
      </div>

      <div
        className={styles.goalGroup}
        style={{
          borderColor: getGroupColor(props.assignmentGroupName as string),
          backgroundColor: `${getGroupColor(
            props.assignmentGroupName as string
          )}22`,
        }}
      >
        {props.skills.map((skill, index) => (
          <div key={index} className={styles.outcome}>
            <p>{skill.skillName}</p>
            {index !== props.skills.length - 1 && (
              <hr
                style={{
                  borderColor: `${getGroupColor(
                    props.assignmentGroupName as string
                  )}44`,
                  border: "none",
                  borderTop: `1px solid ${getGroupColor(
                    props.assignmentGroupName as string
                  )}44`,
                  boxShadow: "none",
                }}
              />
            )}
          </div>
        ))}
      </div>
      {open && (
        <TextArea
          id={`${assignmentNumber}`}
          label="Additional comments"
          required={false}
          value={existingAssignmentGoalComment?.comments || ""}
          onChange={(value) => handleOnchange(value)}
          helpText="Set specific goals for the selected skills in this unit standard"
          aria-disabled={!open}
          disabled={!open}
        />
      )}
    </div>
  );
};
