import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from ".";
import styles from "./button.module.scss";
import { ArrowRightRegular } from "@fluentui/react-icons";
import { AppContext } from "../../AppContext";
import { toSentenceCase } from "../../utils";

interface NavigationButtonProps {
  destination: string;
  content?: string;
}

export const NavigationButton = ({
  destination,
  content,
}: NavigationButtonProps) => {
  const { engagement } = useContext(AppContext);
  const navigate = useNavigate();

  const displayContent = toSentenceCase(content || destination);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const navigateToDestination = () => {
    scrollToTop();
    navigate(`/engagement/${engagement.engagementId}/${destination}`);
  };

  return (
    <div className={styles.navigationContainer}>
      <Button
        className={styles.navigation}
        content={displayContent}
        onClick={navigateToDestination}
        icon={<ArrowRightRegular />}
        iconPosition="after"
      />
    </div>
  );
};

export default NavigationButton;
