import React, { useContext, useEffect, useMemo } from "react";
import styles from "./annualReview.module.scss";
import { BlueAccordion } from "../blueAccordion";
import { TextArea } from "../textArea";
import { AssignmentGroups } from "../../types";
import { AppContext } from "../../AppContext";
import NavigationButton from "../button/navigationButton";
import { ArrowSquareUpRightRegular } from "@fluentui/react-icons";
import { Spinner } from "@fluentui/react-components";
import { toSentenceCase } from "../../utils";

const AnnualReview: React.FC = () => {
  const documentationURL =
    "https://bcito.sharepoint.com/:u:/r/sites/HowWeRollV2/SitePages/Documenting-Visits-%26-Engagements-3.0.aspx?csf=1&web=1&e=MnK1wK";
  const trainerURL =
    "https://bcito.sharepoint.com/:u:/r/sites/HowWeRollV2/SitePages/Supporting-trainers-to-train.aspx?csf=1&web=1&e=MnK1wK";

  const [assignmentGroups, setAssignmentGroups] =
    React.useState<AssignmentGroups>({});

  const { engagement } = useContext(AppContext);
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const milestoneChanges = engagement?.milestones || {};
  const {
    totalPercentageDiff,
    totalCompletionPercentage,
    ...changedAssignmentGroups
  } = milestoneChanges;

  useEffect(() => {
    const fetchMilestones = async () => {
      if (!engagement.learnerId || !engagement.courseId) {
        return;
      }
      setIsFetching(true);
      try {
        const response = await fetch(
          `/api/milestones?studentId=${engagement.learnerId}&courseId=${engagement.courseId}&engagementId=${engagement.engagementId}`
        );

        if (!response.ok) {
          throw new Error(`Error fetching milestones: ${response.status}`);
        }
        const data = await response.json();
        setAssignmentGroups(data);
        setIsFetching(false);
      } catch (error) {
        console.error("Error fetching engagement details: ", error);
      }
    };

    fetchMilestones();
  }, [engagement.learnerId, engagement.courseId]);

  const totalCompletion = useMemo(() => {
    const total = Object.keys(assignmentGroups).reduce<number>(
      (total, groupName) => total + assignmentGroups[groupName].gradeWeighted,
      0
    );
    return Math.floor(total);
  }, [assignmentGroups]);

  const roundedPercentageDiff = Math.ceil(totalPercentageDiff || 0);

  return (
    <div className={styles.root}>
      <div>
        <h1>Annual review</h1>
        <p className={styles.subTitle}>
          Review progress since the last annual review and set goals for the
          next year
        </p>
      </div>
      {
        <BlueAccordion
          title="Overall completion"
          additionalHeaderInfo={
            <div className={styles.completionPercentage}>
              {isFetching && <Spinner />}
              {roundedPercentageDiff > 0 && (
                <span className={styles.completionChange}>
                  +{Math.ceil(totalPercentageDiff)}%
                </span>
              )}
              {totalCompletion}%
            </div>
          }
        >
          <>
            {Object.keys(assignmentGroups).map((assignmentGroupName) => {
              const assignmentGroup = assignmentGroups[assignmentGroupName];
              const percentageComplete = Math.floor(
                assignmentGroup.grade /
                  (assignmentGroup.assignments.length || 1)
              );
              const changePercentage =
                changedAssignmentGroups[assignmentGroup.assignmentGroupId]
                  ?.changePercentage || 0;

              if (assignmentGroupName.startsWith("Artefacts for experienced")) {
                return null;
              }

              return (
                <div
                  key={assignmentGroup.assignmentGroupId}
                  className={styles.milestone}
                >
                  <p>{toSentenceCase(assignmentGroup.assignmentGroupName)}</p>
                  <p className={styles.completionPercentage}>
                    {percentageComplete != 100 && (
                      <span className={styles.completionChange}>
                        {changePercentage > 0 ? "+" : ""}{" "}
                        {changePercentage === 0 && percentageComplete !== 100
                          ? "No change"
                          : `${Math.ceil(changePercentage)}%`}
                      </span>
                    )}

                    <span>{percentageComplete}%</span>
                  </p>
                </div>
              );
            })}
          </>
        </BlueAccordion>
      }
      {!!engagement?.isDraft && engagement.latestAnnualReview && (
        <BlueAccordion title="Previous annual review">
          <>
            <div className={styles.previousAnnualReview}>
              <h3 className={styles.previousReviewTitle}>Annual goals</h3>
              <p>{engagement?.latestAnnualReview?.annual_development_goals}</p>
            </div>
            <div className={styles.previousAnnualReview}>
              <h3 className={styles.previousReviewTitle}>
                Annual goals support plan
              </h3>
              <p>{engagement?.latestAnnualReview?.annual_support_plan}</p>
            </div>
            {!!engagement?.latestAnnualReview?.learning_experience_feedback && (
              <div className={styles.previousAnnualReview}>
                <h3 className={styles.previousReviewTitle}>
                  Learning experience feedback:
                </h3>
                <p>
                  {engagement?.latestAnnualReview?.learning_experience_feedback}
                </p>
              </div>
            )}
            {!!engagement?.latestAnnualReview?.learning_environment && (
              <div className={styles.previousAnnualReview}>
                <h3 className={styles.previousReviewTitle}>
                  Learning environment:
                </h3>
                <p>{engagement?.latestAnnualReview?.learning_environment}</p>
              </div>
            )}
            {!!engagement?.latestAnnualReview?.fees && (
              <div className={styles.previousAnnualReview}>
                <h3 className={styles.previousReviewTitle}>Fees:</h3>
                <p>{engagement?.latestAnnualReview?.fees}</p>
              </div>
            )}
          </>
        </BlueAccordion>
      )}
      <TextArea
        id="annualDevelopmentGoals"
        label="Annual goals"
        helpText="The learner and evaluator's respective annual learning and training goals"
        value={engagement?.annualDevelopmentGoals}
        required
        expandDefault
        info={
          <div className={styles.infoPopup}>
            <h4>What to include:</h4>
            <ul>
              <li>
                What the employer/evaluator said they would do to achieve their
                annual training goals
              </li>
              <li>
                What the apprentice said they would do to achieve their annual
                learning goals
              </li>
            </ul>
            <h4>Example:</h4>
            <p>Training goals for Dave:</p>

            <ul>
              <li>
                Encourage Aroha to bring you solutions rather than problems
              </li>
              <li>
                Teach Aroha how to work from the plans and specs to construct
                wall frames (including raking frames)
              </li>
              <li>
                Continue to increase Aroha's involvement and responsibility with
                tasks
              </li>
              <li>
                Include Aroha when you are locating information in the plans and
                specs
              </li>
            </ul>
            <p>Learning goals for Aroha:</p>
            <ul>
              <li>
                Bring Dave solutions rather than problems if you are unsure what
                to do next or how to do something
              </li>
              <li>
                Learn how to work from the plans and specs to construct wall
                framing
              </li>
              <li>Set out and construct wall framing</li>
              <li>Continue to maintain your study habits</li>
            </ul>
            <a href={`${documentationURL}#annual-goals`} target="_blank">
              Annual goals (HWR)
              <ArrowSquareUpRightRegular />
            </a>
          </div>
        }
      ></TextArea>
      <TextArea
        id="annualSupportPlan"
        label="Annual goals support plan"
        helpText="What has been agreed to do to achieve the learning and training goals"
        value={engagement?.annualSupportPlan}
        required
        expandDefault
        info={
          <div className={styles.infoPopup}>
            <h4>What to include:</h4>
            <ul>
              <li>
                Employer's commentary about their plan to support the apprentice
                to achieve goals.
              </li>
              <li>
                Suggestions for improvement you identified and discussed with
                the employer and apprentice.
              </li>
            </ul>

            <h4>Example:</h4>
            <p>
              Dave said he is pricing a job they will build all their own wall
              framing for to help Aroha become competent with Walls. They have
              the work booked in for Aroha to prove competency with the other
              skill sets mentioned. He will continue to give her increased
              responsibility on the upcoming jobs and check her understanding of
              things before getting her to do anything unsupervised. I suggested
              Dave put any questions Aroha has about what to do or how to do
              something back on to her to think about. Dave said he is keen to
              do this.
            </p>

            <a
              href={`${documentationURL}#annual-goals-support-plan`}
              target="_blank"
            >
              Annual goals support plan (HWR)
              <ArrowSquareUpRightRegular />
            </a>
          </div>
        }
      ></TextArea>
      <TextArea
        id="learningExperienceFeedback"
        label="Learning experience feedback"
        helpText="Learner feedback on their learning journey so far and opportunities for improvement"
        value={engagement?.learningExperienceFeedback}
        required={false}
        info={
          <div className={styles.infoPopup}>
            <h4>What to include:</h4>
            <ul>
              <li>How the learner feels they are progressing</li>
              <li>How they have found their on-job learning experience</li>
              <li>
                What else do they think we could do to support their learning
                journey
              </li>
              <li>
                Do they have any suggestions for how we can we improve the
                learning materials for them
              </li>
            </ul>
            <a
              href={`${documentationURL}#learning-experience-feedback`}
              target="_blank"
            >
              Learning experience feedback (HWR)
              <ArrowSquareUpRightRegular />
            </a>
          </div>
        }
      ></TextArea>
      <TextArea
        id="learningEnvironment"
        label="Learning environment"
        helpText="Discussion about the learning environment since the last annual review"
        value={engagement?.learningEnvironment}
        required={false}
        info={
          <div className={styles.infoPopup}>
            <h4>What to include:</h4>
            <p>
              What did the trainer and apprentice say about the environment that
              they are working in? <br />
              What training strategies is the trainer using? <br />
              What communication is happening between the trainer and learner?
              i.e:{" "}
            </p>
            <ul>
              <li>What type of feedback is the trainer giving the learner?</li>
              <li>
                What positive reinforcement and encouragement of learning is the
                trainer giving?
              </li>
              <li>
                How often is the trainer checking in and reviewing to ensure the
                learner is on track and receiving timely support? (This can
                include referring to the GAT)
              </li>
            </ul>
            <a href={`${trainerURL}`} target="_blank">
              Supporting trainers to train (HWR)
              <ArrowSquareUpRightRegular />
            </a>
            <h4>Example:</h4>
            <p>
              Dave and Aroha are both happy with the learning environment. Dave
              said he has been focussing on giving Aroha positive and
              constructive feedback on her work. Has asked his other staff to do
              the same when training apprentice's and is leading by example with
              this. Dave also said he has set an expectation that his trainers
              use the ‘I do, we do, you do’ strategy when training apprentices.
              Aroha said she is finding it helpful now Dave is using this
              method, whereas before when she was simply told what to do, she
              felt like she was making more mistakes than she would like.
            </p>
            <a
              href={`${documentationURL}#learning-environment`}
              target="_blank"
            >
              Learning environment (HWR)
              <ArrowSquareUpRightRegular />
            </a>
          </div>
        }
      ></TextArea>
      <TextArea
        id="fees"
        label="Fees"
        helpText="Document any conversations had that relate to late-payment reminders, overdue fees, payment plans, funding changes"
        value={engagement?.fees}
        required={false}
      ></TextArea>
      <NavigationButton content={"Next visit"} destination="next-visit" />
    </div>
  );
};

export default AnnualReview;
