import {
  AccordionItem,
  AccordionHeader,
  mergeClasses,
  AccordionPanel,
} from "@fluentui/react-components";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import styles from "./milestone.module.scss";
import Skill from "./Skill";
import { removeGateway, toSentenceCase } from "../../utils";
import {
  Skill as TSkill,
  Assignment as TAssignment,
  AssignmentGroup,
} from "../../types";
import { AppContext, AppDispatchContext, Milestone } from "../../AppContext";

interface Props {
  index: number;
  assignmentName: string;
  assignmentNumber: string;
  assignmentId: number;
  grade: number;
  skills: TSkill[];
  assignmentGroupId: number;
  assignmentGroupName: string;
}

const Assignment: React.FC<Props> = (props) => {
  const {
    index,
    assignmentName,
    assignmentNumber,
    assignmentId,
    grade,
    skills,
    assignmentGroupId,
    assignmentGroupName,
  } = props;

  const dispatch = useContext(AppDispatchContext);
  const { engagement, milestones: milestonesFromCanvas } =
    useContext(AppContext);
  const milestonesRef = useRef(engagement.milestones);

  useEffect(() => {
    milestonesRef.current = engagement.milestones;
  }, [engagement.milestones]);

  const updateEngagement = async (milestones: Record<string, any>) => {
    dispatch({
      type: "SET_ENGAGEMENT",
      payload: { milestones },
    });

    await fetch(`/api/engagements?engagementId=${engagement?.engagementId}`, {
      method: "PUT",
      body: JSON.stringify({
        key: "milestones",
        value: milestones,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
  };

  /* 
      milestonesFromCanvas would have the original data from the Canvas API, would be used to calculate the changes
      milestoneChanges would have the updated data from the user input
      To calculate the changes, we need the weights of assignments and skills from the original data(milestonesFromCanvas)
  */
  const calculateMilestoneChangePercentages = (
    milestoneChanges: Record<string, any>,
    assignmentGroupId: number,
    assignmentId: number
  ) => {
    const engagementMilestonesFromCanvas =
      milestonesFromCanvas.find(
        (m) => m.engagementId === engagement?.engagementId
      ) || ({} as Milestone);

    const assignmentGroupsFromCanvas = Object.keys(
      engagementMilestonesFromCanvas?.assignmentGroups || {}
    ).map((key) => engagementMilestonesFromCanvas?.assignmentGroups[key]);

    const changedAssignmentGroup = milestoneChanges[assignmentGroupId] || {};
    const changedAssignment = changedAssignmentGroup?.[assignmentId] || {};

    const assignmentGroupFromCanvas =
      assignmentGroupsFromCanvas.find(
        (ag) => ag.assignmentGroupId === assignmentGroupId
      ) || ({} as AssignmentGroup);

    const assignmentsFromCanvas = assignmentGroupFromCanvas?.assignments || [];

    const assignmentsCount = assignmentsFromCanvas.length;

    if (!changedAssignmentGroup.assignmentGroupPercentageComplete) {
      const assignmentGroupPercentageComplete = Math.floor(
        assignmentGroupFromCanvas.grade / (assignmentsCount || 1)
      );
      changedAssignmentGroup.assignmentGroupPercentageComplete =
        assignmentGroupPercentageComplete;
    }

    if (!changedAssignment.assignmentPercentageComplete) {
      changedAssignment.assignmentPercentageComplete = Math.floor(grade || 0);
    }

    // Calculate the completion percentage of the assignment
    const assignmentFromCanvas =
      assignmentsFromCanvas.find((a) => a.id === assignmentId) ||
      ({} as TAssignment);

    const skillsFromCanvas = assignmentFromCanvas?.skills || [];
    const totalSkillPoints = skillsFromCanvas.reduce(
      (total: number, s: TSkill) => {
        total += s.points;
        return total;
      },
      0
    );

    const { assignmentName, changePercentage, ...changedSkills } =
      changedAssignment;

    const totalChangedSkillPoints = Object.keys(changedSkills || {}).reduce(
      (total: number, key: string) => {
        const skill = changedSkills[key];
        const skillFromCanvas = skillsFromCanvas.find((s) => s.id === key);

        if (!skill.changedSkillLevelPoints) return total;

        total += !!skillFromCanvas
          ? (skill.changedSkillLevelPoints || 0) -
            (skillFromCanvas?.currentSkillLevel?.points || 0)
          : skill.changedSkillLevelPoints || 0;
        return total;
      },
      0
    );

    const changedAssignmentCompletionPercentage =
      (totalChangedSkillPoints * 100) / totalSkillPoints;

    // Calculate the completion percentage of the assignment group
    const {
      changePercentage: groupChangePercentage,
      assignmentGroupName,
      changePercentageWeighted: groupChangePercentageWeighted,
      ...changedAssignments
    } = changedAssignmentGroup;

    const totalChangedAssignmentPercentage = Object.keys(
      changedAssignments || {}
    ).reduce((total: number, key: string) => {
      const assignment = changedAssignments[key];
      total +=
        Number(key) === assignmentId
          ? changedAssignmentCompletionPercentage
          : assignment.changePercentage || 0;
      return total;
    }, 0);

    const changedAssignmentGroupCompletionPercentage =
      totalChangedAssignmentPercentage / assignmentsCount;

    // Calculate the completion percentage of the all milestones
    const changePercentageWeighted = Object.keys(changedAssignments).reduce(
      (total: number, key: string) => {
        const assignment = changedAssignments[key];
        const assignmentFromCanvas = assignmentsFromCanvas.find(
          (a) => a.id === Number(key)
        );

        const value =
          Number(key) === assignmentId
            ? changedAssignmentCompletionPercentage
            : assignment.changePercentage || 0;

        total += (value * (assignmentFromCanvas?.weight || 0)) / 100;
        return total;
      },
      0
    );

    const {
      totalPercentageDiff,
      totalCompletionPercentage,
      ...changedAssignmentGroups
    } = milestoneChanges;

    const changedTotalPercentage = Object.keys(changedAssignmentGroups).reduce(
      (total: number, key: string) => {
        const assignmentGroup = changedAssignmentGroups[key];
        total +=
          Number(key) === assignmentGroupId
            ? changePercentageWeighted
            : assignmentGroup.changePercentageWeighted;

        return total;
      },
      0
    );

    milestoneChanges.totalPercentageDiff = changedTotalPercentage;
    if (milestoneChanges[assignmentGroupId]) {
      milestoneChanges[assignmentGroupId].changePercentage =
        changedAssignmentGroupCompletionPercentage;
      milestoneChanges[assignmentGroupId].changePercentageWeighted =
        changePercentageWeighted;

      if (milestoneChanges[assignmentGroupId][assignmentId]) {
        milestoneChanges[assignmentGroupId][assignmentId].changePercentage =
          changedAssignmentCompletionPercentage;
      }
    }

    return milestoneChanges;
  };

  const onResetSkillChange = useCallback(
    (skill: TSkill) => {
      let {
        totalPercentageDiff,
        totalCompletionPercentage,
        ...milestoneChanges
      } = milestonesRef.current || {};

      const { id: skillId } = skill;

      if (milestoneChanges[assignmentGroupId]?.[assignmentId]?.[skillId])
        delete milestoneChanges[assignmentGroupId][assignmentId][skillId];

      const {
        assignmentName,
        changePercentage,
        assignmentPercentageComplete,
        ...otherSkills
      } = milestoneChanges[assignmentGroupId]?.[assignmentId] || {};

      if (Object.keys(otherSkills || {}).length === 0) {
        if (milestoneChanges[assignmentGroupId]?.[assignmentId])
          delete milestoneChanges[assignmentGroupId]?.[assignmentId];
        const {
          assignmentGroupName,
          changePercentage,
          assignmentGroupPercentageComplete,
          changePercentageWeighted,
          ...otherAssignments
        } = milestoneChanges[assignmentGroupId] || {};

        if (Object.keys(otherAssignments || {}).length === 0) {
          if (milestoneChanges[assignmentGroupId])
            delete milestoneChanges[assignmentGroupId];
        }
      }

      milestoneChanges = calculateMilestoneChangePercentages(
        milestoneChanges,
        assignmentGroupId,
        assignmentId
      );

      updateEngagement(milestoneChanges);

      dispatch({
        type: "SET_ENGAGEMENT",
        payload: { milestones: milestoneChanges },
      });
    },
    [engagement.milestones]
  );

  const onSkillUpdate = useCallback(
    async (skill: TSkill, data: Record<string, any>) => {
      const { id: skillId, criterion: skillName } = skill;
      const milestoneChanges = milestonesRef.current || {};

      let milestones = {
        ...milestoneChanges,
        [`${assignmentGroupId}`]: {
          ...milestoneChanges[assignmentGroupId],
          assignmentGroupName,
          [`${assignmentId}`]: {
            ...milestoneChanges[assignmentGroupId]?.[assignmentId],
            assignmentName: `${assignmentNumber} - ${assignmentName}`,
            [skillId]: {
              ...milestoneChanges[assignmentGroupId]?.[assignmentId]?.[skillId],
              skillName,
              ...data,
            },
          },
        },
      };

      if (typeof data.changedSkillLevelPoints !== "undefined") {
        milestones = calculateMilestoneChangePercentages(
          milestones,
          assignmentGroupId,
          assignmentId
        );
      }

      updateEngagement(milestones);

      dispatch({
        type: "SET_ENGAGEMENT",
        payload: { milestones },
      });
    },
    [engagement.milestones]
  );

  const { totalPercentageDiff, totalCompletionPercentage, ...milestoneItems } =
    engagement.milestones || {};

  const assignmentGroup = (milestoneItems || {})[assignmentGroupId];
  const changedAssignment = assignmentGroup?.[assignmentId];
  const assignmentChangePercentage = changedAssignment?.changePercentage || 0;
  return (
    <AccordionItem
      value={index}
      className={styles.accordionItem}
      key={assignmentId}
    >
      <AccordionHeader
        icon={null}
        expandIcon={null}
        className={
          assignmentName.startsWith("Worksheet")
            ? styles.accordionHeaderNoChildren
            : styles.accordionHeader
        }
      >
        <div className={styles.assignmentHeaderWrapper}>
          <h3
            className={
              assignmentName.startsWith("Worksheet")
                ? styles.unitStandardTitleNoChildren
                : styles.unitStandardTitle
            }
          >
            {toSentenceCase(removeGateway(assignmentName))}{" "}
            {assignmentNumber && <span>({assignmentNumber})</span>}
          </h3>
          <div className={styles.unitStandardPercentage}>
            {assignmentChangePercentage !== 0 && (
              <span className={styles.unitStandardPercentageChange}>
                +{Math.ceil(assignmentChangePercentage)}%
              </span>
            )}
            <span className={styles.unitStandardPercentageTotal}>
              {Math.floor(grade)}%
            </span>
          </div>
        </div>
        {!assignmentName.startsWith("Worksheet") && (
          <div className={styles.unitStandardCompletionOuterBar}>
            <div
              className={mergeClasses(
                styles.unitStandardCompletionBar,
                grade === 100 ? styles.completed : ""
              )}
              style={{ width: `${grade}%` }}
            ></div>
            <div
              className={mergeClasses(
                styles.unitStandardCompletionBar,
                styles.unitStandardChangeBar,
                grade === 0 ? styles.leftRoundedBar : "",
                grade === 100 ? styles.completed : "",
                Math.ceil(assignmentChangePercentage + grade) >= 100
                  ? styles.rightRoundedBar
                  : ""
              )}
              style={{ width: `${assignmentChangePercentage}%` }}
            ></div>
          </div>
        )}
      </AccordionHeader>
      <AccordionPanel className={styles.accordionPanel} as="div">
        {skills.map((skill) => (
          <Skill
            {...skill}
            key={skill.id}
            assignmentGroupId={assignmentGroupId}
            assignmentId={assignmentId}
            assignmentName={assignmentName}
            assignmentNumber={assignmentNumber}
            assignmentGroupName={assignmentGroupName}
            onSkillUpdate={(data) => onSkillUpdate(skill, data)}
            onResetSkillChange={() => onResetSkillChange(skill)}
          />
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Assignment;
