import {
  AppContext,
  AppDispatchContext,
  appReducer,
  initialAppState,
} from "./AppContext";
import { useEffect, useReducer, useState } from "react";
import AuthLoader from "./AuthLoader";
import {
  AuthContext,
  AuthDispatchContext,
  authReducer,
  initialAuthState,
} from "./AuthContext";
import AppLayout from "./AppLayout";
import Toast from "./components/toast";
import { FormProvider, useForm } from "react-hook-form";

function App() {
  const [appState, dispatch] = useReducer(appReducer, initialAppState);
  const [authState, authDispatch] = useReducer(authReducer, initialAuthState);
  const [retryCallback, setRetryCallback] = useState<null | Function>(null);
  const [showToast, setShowToast] = useState(false);
  const methods = useForm();

  useEffect(() => {
    const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {
      try {
        setShowToast(false);
        const response = await originalFetch(...args);
        if (response.status === 401) {
          await fetch(`/api/auth/oauth`, { method: "DELETE" });
          window.location.href = "/api/auth/oauth";
        }
        if (!response.ok && args[1]?.method === "PUT")
          throw new Error(`HTTP error! status: ${response.status}`);
        return response;
      } catch (error) {
        const url = args[0];
        const options = args[1] || {};
        setShowToast(true);
        setRetryCallback(() => () => window.fetch(url, options));
        throw error;
      }
    };
  }, []);

  const handleRetry = () => {
    if (retryCallback) {
      retryCallback();
      setShowToast(false);
    }
  };

  return (
    <AuthContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={authDispatch}>
        <AppContext.Provider value={appState}>
          <AppDispatchContext.Provider value={dispatch}>
            <AuthLoader>
              <FormProvider {...methods}>
                <AppLayout />
                {showToast && <Toast callback={handleRetry}></Toast>}
              </FormProvider>
            </AuthLoader>
          </AppDispatchContext.Provider>
        </AppContext.Provider>
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
