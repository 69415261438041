import {
  useId,
  Link,
  Toaster,
  useToastController,
  Toast,
  ToastFooter,
  ToastTitle,
  ToastTrigger,
} from "@fluentui/react-components";
import { useEffect } from "react";

interface Props {
  callback: () => void;
}
const MyToast = (props: Props) => {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  useEffect(() => {
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          Something went wrong
        </ToastTitle>
        <ToastFooter>
          <Link onClick={() => props.callback()}>Please try again</Link>
        </ToastFooter>
      </Toast>,
      { intent: "error" }
    );
  }, []);

  return <Toaster toasterId={toasterId} />;
};

export default MyToast;
