import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
} from "@fluentui/react-components";
import { ChevronUpFilled, ChevronDownFilled } from "@fluentui/react-icons";
import styles from "./blueAccordion.module.scss";

interface Props {
  children: React.ReactElement;
  title: string;
  additionalHeaderInfo?: React.ReactElement;
}

export const BlueAccordion: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleToggle = React.useCallback<AccordionToggleEventHandler>(
    (_, data) => {
      setOpen(data.openItems.length === 1);
    },
    []
  );

  return (
    <Accordion onToggle={handleToggle} collapsible className={styles.root}>
      <AccordionItem value={1}>
        <AccordionHeader
          expandIcon={
            open ? (
              <ChevronUpFilled fontSize={32} />
            ) : (
              <ChevronDownFilled fontSize={32} />
            )
          }
          expandIconPosition="end"
        >
          <div className={styles.accordionHeader}>
            <h3>{props.title}</h3>
            {props.additionalHeaderInfo || <></>}
          </div>
        </AccordionHeader>
        <AccordionPanel className={styles.accordionPanel}>
          {props.children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
