import {
  Button,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  MenuDivider,
  MenuItemLink,
  Switch,
} from "@fluentui/react-components";
import {
  MoreVerticalFilled,
  ArrowExitRegular,
  ArrowSquareUpRightRegular,
  PersonFilled,
  BroomRegular,
} from "@fluentui/react-icons";
import styles from "./subMenu.module.scss";
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import { AppContext, AppDispatchContext } from "../../AppContext";

export const SubMenu = () => {
  const { userInfo } = useContext(AuthContext);
  const dispatch = useContext(AppDispatchContext);
  const { offlineMode } = useContext(AppContext);

  const handleLogout = async () => {
    await fetch(`/api/auth/oauth`, { method: "DELETE" });
    window.location.href = "/";
  };
  const clearData = async () => {
    await fetch(`/api/auth/oauth?reauthorise=1`, { method: "DELETE" });
    window.location.href = "/";
  };

  const toggleOfflineMode = () => {
    dispatch({ type: "SET_OFFLINE_MODE", payload: !offlineMode });
    dispatch({ type: "SET_NETWORK_STATUS", payload: offlineMode });
  };

  return (
    <div className={styles.root}>
      <Menu hasIcons>
        <MenuTrigger disableButtonEnhancement>
          <Button className={styles.button} title="Settings">
            <MoreVerticalFilled />
          </Button>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItem icon={<PersonFilled />} style={{ pointerEvents: "none" }}>
              <strong>{userInfo?.name}</strong>
            </MenuItem>
            <MenuDivider />
            <MenuItem className={styles.offlineToggle}>
              Offline mode
              <Switch
                name="offlineMode"
                value="offlineMode"
                checked={offlineMode}
                onClick={toggleOfflineMode}
              />
            </MenuItem>
            <MenuItemLink
              icon={<ArrowSquareUpRightRegular />}
              href="https://forms.office.com/r/Tw9eiTQWYP"
              target="blank"
            >
              Give feedback on Hono
            </MenuItemLink>
            <MenuItem onClick={clearData} icon={<BroomRegular />}>
              Reset authentication
            </MenuItem>
            <MenuItem icon={<ArrowExitRegular />} onClick={handleLogout}>
              Log out
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  );
};
