import React, { useContext, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import DashboardItem from "../components/dashboardItem";
import styles from "./dashboard.module.scss";
import {
  EngagementEvent,
  TEngagement,
  TEngagementDb,
  TLearner,
} from "../types";
import { AppContext, AppDispatchContext } from "../AppContext";
import { AddLearner } from "../components/addLearner";
import { UnscheduledLearner } from "../components/unscheduledLearner";
import { transformDbToUI } from "../utils";
import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogContent,
  Spinner,
} from "@fluentui/react-components";
import Swipeable from "../utils/Swipeable";
import { swipeDateChange } from "../components/header/SubHeader";

const Dashboard: React.FC = () => {
  const {
    engagementDate,
    isEngagementDateToday,
    online,
    learners: learnersFromContext,
  } = useContext(AppContext);
  const dispatch = useContext(AppDispatchContext);
  const [engagementsFetching, setEngagementsFetching] = useState(true);
  const [events, setEvents] = useState<EngagementEvent[]>([]);

  const fetchLearnersCRMData = async (userId: string) => {
    const response = await fetch(`/api/crm-data?learnerSisUserId=${userId}`);
    if (!response.ok) {
      throw new Error(
        `Error fetching Hapai data for use ${userId}: ${response.status}`
      );
    }
    const data = await response.json();
    return { learnerSisUserId: userId, ...data };
  };

  const fetchLearners = async (): Promise<TLearner[] | undefined> => {
    if (learnersFromContext?.length > 0) {
      return learnersFromContext;
    }

    const response = await fetch(`/api/learners`);
    if (!response.ok) {
      throw new Error("Error fetching learners");
    }
    const learners = await response.json();
    dispatch({ type: "SET_LEARNERS", payload: learners });
    return learners;
  };

  const fetchMilestones = async (engagement: TEngagement) => {
    const response = await fetch(
      `/api/milestones?studentId=${engagement.learnerId}&courseId=${engagement.courseId}&engagementId=${engagement.engagementId}`
    );

    if (!response.ok) {
      throw new Error(`Error fetching milestones: ${response.status}`);
    }
    const data = await response.json();
    return { engagementId: engagement.engagementId, assignmentGroups: data };
  };

  const fetchEngagements = async (): Promise<TEngagement[] | undefined> => {
    setEngagementsFetching(true);
    try {
      const response = await fetch(
        `/api/engagements?timestamp=${engagementDate}`
      );
      setEngagementsFetching(false);
      if (!response.ok) {
        throw new Error("Error fetching engagement list");
      }

      const engagements = await response.json();
      const transformedData = engagements.map((engagement: TEngagementDb) =>
        transformDbToUI(engagement)
      );
      dispatch({ type: "SET_ENGAGEMENTS", payload: transformedData });

      setEvents(transformedData);
      return transformedData;
    } catch (error) {
      setEngagementsFetching(false);
    }
  };

  useEffect(() => {
    Promise.all([fetchEngagements(), fetchLearners()])
      .then((data) => {
        const [engagements = [], learners = []] = data;
        // if engagement date is not today we don't need to fetch Hapai data for all learners
        if (!isEngagementDateToday) {
          return;
        }

        const learnerIds = engagements.map(
          (engagement) => engagement.learnerId
        );

        const learnerSisUserIds = learnerIds.map(
          (learnerId) =>
            learners.find((learner) => learner.learnerId === learnerId)
              ?.learnerSisUserId || ""
        );

        Promise.all(learnerSisUserIds.map(fetchLearnersCRMData))
          .then((data) => {
            dispatch({
              type: "SET_LEARNERS_CRM_DATA",
              payload: data,
            });
          })
          .catch((error) => {
            console.error("Error fetching CRM data", error);
          });

        Promise.all(engagements.map(fetchMilestones))
          .then((data) => {
            dispatch({
              type: "SET_MILESTONES",
              payload: data,
            });
          })
          .catch((error) => {
            console.error("Error fetching milestones", error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [engagementDate, isEngagementDateToday]);

  const onAddEngagement = (learnerSisUserId: string) => {
    // refetch engagement list
    fetchEngagements();
    fetchLearnersCRMData(learnerSisUserId).then((data) => {
      dispatch({
        type: "SET_LEARNERS_CRM_DATA",
        payload: [data],
      });
    });
  };

  // function that checks the events and if there is gap between 6am to 6pm, it adds a new event
  const eventsWithEmptySlots = useMemo(() => {
    const newEvents: EngagementEvent[][] = [];

    for (let i = 6; i < 18; i++) {
      const time = i < 10 ? `0${i}` : `${i}`;

      const emptySlot = {
        hourlySlotStartTime: `${time}:00`,
        scheduledTimeHour: undefined,
        learnerId: NaN,
        learnerName: "",
        courseId: NaN,
        courseName: "",
        submitted: false,
        engagementId: 0,
        isDraft: false,
        scheduledTime: "",
        engagementDate: 0,
      } as EngagementEvent;

      const slottedEvents = events.filter((event) => {
        const scheduledTimestamp = Number(event.scheduledTime);

        const eventTime = dayjs(scheduledTimestamp).format("HH");

        return eventTime === time;
      });

      // if there are no events at this time, add an empty slot
      // in any schedule time there can be multiple engagements.

      if (!slottedEvents.length) {
        newEvents.push([emptySlot]);
      } else {
        newEvents.push(
          slottedEvents.map((event) => {
            const scheduledTimestamp = Number(event.scheduledTime);
            const scheduledTimeHour = dayjs(scheduledTimestamp).format("HH:mm");
            return {
              ...event,
              scheduledTimeHour,
              hourlySlotStartTime: `${time}:00`,
            };
          })
        );
      }
    }
    return newEvents;
  }, [events]);

  return (
    <>
      <Swipeable
        onSwipeLeft={() => online && swipeDateChange("left")}
        onSwipeRight={() => online && swipeDateChange("right")}
      >
        <div className={styles.root}>
          <ul>
            {eventsWithEmptySlots.map((event: EngagementEvent[]) => (
              <li key={event[0].hourlySlotStartTime}>
                <DashboardItem
                  setEvents={setEvents}
                  events={event}
                  learners={learnersFromContext}
                />
              </li>
            ))}
          </ul>
        </div>
        {engagementsFetching && (
          <Dialog open={true}>
            <DialogSurface>
              <DialogBody>
                <DialogContent className={styles.dialogContent}>
                  <Spinner />
                  <span>Fetching engagements...</span>
                </DialogContent>
              </DialogBody>
            </DialogSurface>
          </Dialog>
        )}

        <div className={styles.footer}>
          <AddLearner onSuccess={onAddEngagement} />
          {false && <UnscheduledLearner />}
        </div>
      </Swipeable>
    </>
  );
};

export default Dashboard;
