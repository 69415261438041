import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { PersonFilled } from "@fluentui/react-icons";
import styles from "./studentName.module.scss";

const StudentName: React.FC = ({}) => {
  const { engagement } = useContext(AppContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isStickied, setIsStickied] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsStickied(!entry.isIntersecting),
      { threshold: [1] }
    );
    if (containerRef.current) observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={containerRef}
      className={styles.studentNameContainer}
      style={{ zIndex: isStickied ? 1000 : 1 }}
    >
      <PersonFilled />
      <p className={styles.studentName}>{engagement.learnerName}</p>
    </div>
  );
};

export default StudentName;
