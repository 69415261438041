import {
  Checkbox as CheckboxBase,
  Field,
  mergeClasses,
} from "@fluentui/react-components";
import type {
  CheckboxOnChangeData,
  CheckboxProps,
} from "@fluentui/react-components";
import styles from "./checkbox.module.scss";
import {
  useEffect,
  useCallback,
  ChangeEvent,
  useContext,
  useState,
} from "react";

import { AppContext } from "../../AppContext";

type Props = CheckboxProps & { id: string };

export const Checkbox = (props: Props) => {
  const [checked, setChecked] = useState<boolean>(props.checked as boolean);
  const { engagement } = useContext(AppContext);

  useEffect(() => {
    setChecked(props.checked as boolean);
  }, [props.checked]);

  const onChange = useCallback(
    async (_: ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
      setChecked(data.checked as boolean);

      props.onChange?.(_, data);
    },
    [engagement]
  );
  return (
    <Field className={styles.root}>
      <CheckboxBase
        {...props}
        size="large"
        onChange={onChange}
        checked={checked}
        indicator={{
          className: mergeClasses(
            styles.checkboxIndicator,
            checked === true && props.disabled
              ? styles.checkboxCheckedDisabled
              : ""
          ),
        }}
        root={{ className: styles.checkbox }}
      />
    </Field>
  );
};
