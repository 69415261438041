import React, { useContext, useEffect, useMemo } from "react";
import { LastEngagement } from "./LastEngagement";
import styles from "./progress.module.scss";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
} from "@fluentui/react-components";
import { TextArea } from "../textArea";
import { AppContext, AppDispatchContext } from "../../AppContext";
import { AssignmentGroups, MilestoneChange } from "../../types";
import { getMileStoneChanges } from "../../utils";
import { useLocation } from "react-router-dom";
import NavigationButton from "../button/navigationButton";
import MilestoneStatement from "./MilestoneStatement";
import { ArrowSquareUpRightRegular } from "@fluentui/react-icons";
import { fieldInfo } from "../../constants";

const Progress: React.FC = () => {
  const documentationURL =
    "https://bcito.sharepoint.com/:u:/r/sites/HowWeRollV2/SitePages/Documenting-Visits-%26-Engagements-3.0.aspx?csf=1&web=1&e=MnK1wK";

  const location = useLocation();
  const { hash } = location;
  const defaultOpenItemIndex = hash?.match(/\d/) ? "2" : "1";
  const [openItems, setOpenItems] = React.useState([defaultOpenItemIndex]);
  const { engagement, editMode, milestones, learners } = useContext(AppContext);
  const dispatch = useContext(AppDispatchContext);
  const [assignmentGroupsFromCanvas, setAssignmentGroupsFromCanvas] =
    React.useState<AssignmentGroups>({});

  useEffect(() => {
    if (!engagement.learnerId || !engagement.courseId) {
      return;
    }

    const milestone = milestones?.find(
      (m) => m.engagementId === engagement.engagementId
    );

    const hasAssignmentGroups =
      Object.keys(milestone?.assignmentGroups || {}).length > 0;

    if (hasAssignmentGroups && milestone) {
      const { assignmentGroups } = milestone;
      setAssignmentGroupsFromCanvas(assignmentGroups);
      return;
    }

    const fetchMilestones = async () => {
      try {
        const response = await fetch(
          `/api/milestones?studentId=${engagement.learnerId}&courseId=${engagement.courseId}&engagementId=${engagement.engagementId}`
        );
        if (!response.ok) {
          throw new Error(`Error fetching milestones: ${response.status}`);
        }
        const data = await response.json();

        dispatch({
          type: "SET_MILESTONE",
          payload: {
            engagementId: engagement?.engagementId,
            assignmentGroups: data,
          },
        });
        setAssignmentGroupsFromCanvas(data);
      } catch (error) {
        console.error("Error fetching engagement details: ", error);
      }
    };

    fetchMilestones();
  }, [milestones, engagement.learnerId, engagement.courseId]);

  const handleToggle: AccordionToggleEventHandler<string> = (_, data) => {
    setOpenItems(data.openItems);
  };

  const milestoneChanges = useMemo<MilestoneChange[]>((): MilestoneChange[] => {
    if (!engagement?.milestones) return [];

    const learner = learners.find(
      (l) =>
        l.learnerId === engagement?.learnerId &&
        l.courseId === engagement?.courseId
    );
    return getMileStoneChanges(
      engagement,
      assignmentGroupsFromCanvas || {},
      learner?.courseDescription
    );
  }, [engagement?.engagementId, learners, assignmentGroupsFromCanvas]);

  const hasRequiredFields = useMemo<boolean>(() => {
    for (const milestoneChange of milestoneChanges) {
      const hasRequiredField = (milestoneChange.fields || []).some(
        (field: string) => fieldInfo[field].required as boolean
      );
      if (hasRequiredField) {
        return true;
      }
    }
    return false;
  }, [milestoneChanges]);

  return (
    <>
      <h1>Progress review</h1>
      <p className={styles.subTitle}>
        Document the work completed, milestones moved and discuss the learning
        journey
      </p>
      {!!engagement?.isDraft && <LastEngagement />}
      <Accordion
        openItems={openItems}
        onToggle={handleToggle}
        multiple
        collapsible
      >
        <AccordionItem value="1" className={styles.accordionItem}>
          <AccordionHeader
            expandIconPosition="end"
            className={styles.accordionHeader}
          >
            Projects completed
            <span className={styles.required}>(required)</span>
          </AccordionHeader>
          <AccordionPanel className={styles.accordionPanel}>
            <TextArea
              id="currentWork"
              helpText="Briefly describe the project the learner is currently working on and their involvement"
              label="Current project"
              disabled={!editMode}
              info={
                <div className={styles.infoPopup}>
                  <h4>What to include:</h4>
                  <ul>
                    <li>
                      Site location (street/lot number, street name, suburb)
                    </li>
                    <li>
                      Project description (company workshop name, size/volume or
                      complexity if applicable)
                    </li>
                    <li>Current stage of the project</li>
                  </ul>

                  <h4>Example:</h4>
                  <p>
                    3 Sunrise Terrace, Maraetai - 220m2 2 story new build from
                    ground up. Concrete floor, pre-nail frames and trusses, Mono
                    pitch roof, Ply/batten cladding, 60m2 ground floor timber
                    deck on pile foundations. Approaching completion - currently
                    finishing off laying the decking.
                  </p>
                  <a
                    href={`${documentationURL}#current-project`}
                    target="_blank"
                  >
                    Current project (HWR)
                    <ArrowSquareUpRightRegular />
                  </a>
                </div>
              }
              required
              value={engagement?.currentWork}
            />
            <TextArea
              id="projectsWorkedOn"
              helpText="Briefly describe the projects the learner has worked on since the last visit, including summaries of any projects uploaded to myBCITO"
              label="Recent projects"
              disabled={!editMode}
              required
              value={engagement?.projectsWorkedOn}
              info={
                <div className={styles.infoPopup}>
                  <h4>What to include:</h4>
                  <ul>
                    <li>
                      Site location (street/lot number, street name, suburb)
                    </li>
                    <li>
                      Project details (company workshop name, size/volume or
                      complexity if applicable)
                    </li>
                    <li>Current stage of each project</li>
                  </ul>
                  <h4>Example:</h4>
                  <ul>
                    <li>
                      255 3rd Ave, St Heliers - 295m2 3 story Reclad/Renovation.
                      Demolition, Remedial work to framing, new timber BB
                      weatherboards on new cavity system, aluminium joinery and
                      interior re-fit. Currently at final fit-off, installing
                      door hardware.
                    </li>
                    <li>
                      134 Gerard Road, Beachlands - 270m2 single-level
                      renovation. New timber joinery, new kitchen/bathroom, and
                      some minor remodeling. Currently at the finishing stage.
                    </li>
                  </ul>
                  <p>
                    If recent projects are not different from the current
                    project, include a statement about the learner continuing to
                    work on the same site/project since the last visit.
                  </p>
                  <a
                    href={`${documentationURL}#recent-projects`}
                    target="_blank"
                  >
                    Recent projects (HWR)
                    <ArrowSquareUpRightRegular />
                  </a>
                </div>
              }
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          value="2"
          className={styles.accordionItem}
          style={{ display: milestoneChanges.length == 0 ? "none" : "block" }}
        >
          <AccordionHeader
            expandIconPosition="end"
            className={styles.accordionHeader}
          >
            Milestone statements
            {hasRequiredFields ? (
              <span className={styles.required}>(required)</span>
            ) : (
              <span className={styles.notRequired}>(optional)</span>
            )}
          </AccordionHeader>
          <AccordionPanel className={styles.accordionPanel}>
            {milestoneChanges?.map((milestoneChange, index) => (
              <MilestoneStatement key={index} {...milestoneChange} />
            ))}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem value="3" className={styles.accordionItem}>
          <AccordionHeader
            expandIconPosition="end"
            className={styles.accordionHeader}
          >
            Learning journey
            <span className={styles.notRequired}>(optional)</span>
          </AccordionHeader>
          <AccordionPanel className={styles.accordionPanel}>
            <TextArea
              id="learnerEngagement"
              helpText="Document conversations related to the learners engagement with the qualification e.g. myBCITO, books, block courses"
              label="Learner engagement"
              required={false}
              value={engagement?.learnerEngagement}
              disabled={!editMode}
            />

            <TextArea
              id="languageLiteracyNumeracy"
              helpText="Document conversations that happened and the outcome i.e - Setting the learner up with external support, completing an LLN assessment outside of a F2F visit etc. "
              label="Language, literacy and numeracy"
              required={false}
              value={engagement?.languageLiteracyNumeracy}
              disabled={!editMode}
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem value="4" className={styles.accordionItem}>
          <AccordionHeader
            expandIconPosition="end"
            className={styles.accordionHeader}
          >
            Comments<span className={styles.notRequired}>(optional)</span>
          </AccordionHeader>
          <AccordionPanel className={styles.accordionPanel}>
            <TextArea
              id="evaluatorComments"
              helpText="General feedback from the evaluator related to their progress"
              label="Evaluator comments"
              required={false}
              value={engagement?.evaluatorComments}
              disabled={!editMode}
            />
            <TextArea
              id="learnerComments"
              helpText="General feedback from the learner related to their progress"
              label="Learner comments"
              labelSuffix="optional"
              value={engagement?.learnerComments}
              disabled={!editMode}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <div className={styles.buttonContainer}>
        <NavigationButton destination={"goals"} />
      </div>
    </>
  );
};

export default Progress;
