import React from "react";
import { Outlet } from "react-router-dom";
import { TabList } from "../components/tabList";
import styles from "./engagementWrapper.module.scss";
import StudentName from "../components/studentName/studentName";

const EngagementWrapper: React.FC = () => {
  return (
    <div className={styles.root}>
      <TabList />
      <StudentName />
      <div className={styles.container}>
        <Outlet />
      </div>
    </div>
  );
};

export default EngagementWrapper;
