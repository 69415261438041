import React, { useContext, useEffect, useState } from "react";
import { ArrowDownloadFilled } from "@fluentui/react-icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import styles from "./header.module.scss";
import SubHeader from "./SubHeader";
import BetaBanner from "./BetaBanner";
import { AppDispatchContext } from "../../AppContext";
import { CompoundButton } from "../compoundButton";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  prompt(): Promise<void>;
}

const Header: React.FC = () => {
  const appInsights = useAppInsightsContext();
  const dispatch = useContext(AppDispatchContext);
  const [showInAppInstallPromotion, setShowInAppInstallPromotion] =
    useState(false);

  const [installPromptEvent, setInstallPromptEvent] = useState<
    BeforeInstallPromptEvent | undefined
  >(undefined);

  const getRandomString = () => Math.random().toString(36).substring(2, 15);

  async function isOnline() {
    if (!window.navigator.onLine) return false;

    // avoid CORS errors with a request to your own origin
    const url = new URL(window.location.origin);

    // random value to prevent cached responses
    url.searchParams.set("rand", getRandomString());

    try {
      const response = await fetch(url.toString(), { method: "HEAD" });
      return response.ok;
    } catch {
      return false;
    }
  }

  // Adding event listeners for online/offline events
  useEffect(() => {
    isOnline().then((online) => {
      dispatch({ type: "SET_NETWORK_STATUS", payload: online });
    });

    window.addEventListener(
      "offline",
      () => dispatch({ type: "SET_NETWORK_STATUS", payload: false }),
      false
    );
    window.addEventListener(
      "online",
      () => dispatch({ type: "SET_NETWORK_STATUS", payload: true }),
      false
    );
    return () => {
      window.removeEventListener("offline", () => {}, false);
      window.removeEventListener("online", () => {}, false);
    };
  }, []);

  useEffect(() => {
    const handleServiceWorkerUpdate = () => {
      localStorage.setItem("swInstalledAt", Date.now().toString());
      window.location.reload();
    };

    navigator.serviceWorker.addEventListener(
      "controllerchange",
      handleServiceWorkerUpdate
    );

    return () => {
      navigator.serviceWorker.removeEventListener(
        "controllerchange",
        handleServiceWorkerUpdate
      );
    };
  }, []);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      setInstallPromptEvent(e as BeforeInstallPromptEvent);
      setShowInAppInstallPromotion(true);
    });
  }, []);

  // Handling hard refresh on service worker update
  useEffect(() => {
    (async () => {
      if (!navigator.serviceWorker.controller) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        await Promise.all(registrations.map((r) => r.unregister()));
      }
    })();
  }, []);

  useEffect(() => {
    navigator.serviceWorker.register("/sw.js").then((registration) => {
      const updateOnlineStatus = () => {
        registration.active?.postMessage({
          type: "SET_ONLINE_STATUS",
          online: navigator.onLine,
        });
      };

      window.addEventListener("online", updateOnlineStatus);
      window.addEventListener("offline", updateOnlineStatus);
      updateOnlineStatus();
    });
  }, []);

  useEffect(() => {
    navigator.serviceWorker.ready.then(function (registration) {
      // @ts-ignore
      if (registration.sync) {
        // @ts-ignore
        return registration.sync?.register("sync-actions");
      }
    });
  }, []);

  // Handle unauthorized access
  useEffect(() => {
    navigator.serviceWorker.addEventListener("message", async (event) => {
      switch (event.data.type) {
        case "UNAUTHORIZED": {
          await fetch(`/api/auth/oauth`, { method: "DELETE" });
          window.location.href = `/api/auth/oauth`;
          break;
        }
        case "SYNC_FAILURE": {
          appInsights.trackEvent({
            name: "SyncFailure",
            properties: { count: event.data?.message?.count },
          });
          break;
        }

        case "SYNC_SUCCESS": {
          if (event.data?.message?.count <= 0) {
            return;
          }
          appInsights.trackEvent({
            name: "SyncSuccess",
            properties: {
              count: event.data?.message?.count,
            },
          });
          break;
        }
        default:
          break;
      }
    });
  }, []);

  const handleClick = async () => {
    if (installPromptEvent) {
      (installPromptEvent as any).prompt();
      setShowInAppInstallPromotion(false);
      const { outcome } = await installPromptEvent?.userChoice;
      // The deferredPrompt can only be used once.
      setInstallPromptEvent(undefined);
      // Act on the user's choice
      if (outcome === "accepted") {
        console.log("User accepted the install prompt.");
      } else if (outcome === "dismissed") {
        console.log("User dismissed the install prompt");
      }
    }
  };

  const isBeta =
    window.location.hostname === "beta.hono.bcito.org.nz" ||
    window.location.hostname === "localhost";

  return (
    <>
      {isBeta ? (
        <BetaBanner />
      ) : showInAppInstallPromotion ? (
        <div className={styles.installBanner}>
          <span>
            You are using Hono in a web browser, install it on your device for
            the best experience
          </span>
          <CompoundButton
            content="Install on device"
            icon={<ArrowDownloadFilled />}
            style={{ padding: "6px 18px", backgroundColor: "#EB5014" }}
            onClick={handleClick}
          />
        </div>
      ) : (
        <></>
      )}
      <header className={styles.root}>
        <img
          className={styles.logo}
          src="/bcito-logo-white.svg"
          alt="bcito logo"
        />
        <img className={styles.hono} src="/hono-logo.svg" alt="hono logo" />
      </header>
      <div>
        <SubHeader />
      </div>
    </>
  );
};

export default Header;
