import { createContext } from "react";

export const initialAuthState: AuthState = {
  isLoggedIn: false,
  userInfo: undefined,
};

export interface AuthState {
  isLoggedIn: boolean;
  userInfo?: {
    name: string;
    uid: string;
  };
}

// Define the action types
export type AuthAction =
  | { type: "SET_USER_INFO"; payload?: { name: string; uid: string } }
  | { type: "SET_LOGIN_STATUS"; payload: boolean };

export const AuthContext = createContext<AuthState>(null as any);

export const AuthDispatchContext = createContext<React.Dispatch<AuthAction>>(
  null as any
);

export function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "SET_USER_INFO":
      return { ...state, userInfo: action.payload };
    case "SET_LOGIN_STATUS":
      return action.payload
        ? { ...state, isLoggedIn: action.payload }
        : { ...state, userInfo: undefined, isLoggedIn: action.payload };

    default:
      return state;
  }
}
